import { ErrorMessage } from "@hookform/error-message";
import clsx from "clsx";
import { useRegions } from "medusa-react";
import React, { forwardRef, useImperativeHandle, useMemo, useRef } from "react";
import { get, useFormContext, useWatch } from "react-hook-form";

import { AddressInterface } from "@/components/pages/account/AddAddress";
import { translateCountry } from "@/lib/util/translate-country";
import NativeSelect, { NativeSelectProps } from "@/modules/common/components/native-select";

const CountrySelect = forwardRef<HTMLSelectElement, NativeSelectProps & { name: string }>(
  ({ errors, name, className, placeholder = "Country", ...props }, ref) => {
    const innerRef = useRef<HTMLSelectElement>(null);

    useImperativeHandle<HTMLSelectElement | null, HTMLSelectElement | null>(ref, () => innerRef.current);

    const { regions } = useRegions();

    const countryOptions = useMemo(() => {
      // @todo löst "Shipping country must be in the cart region" aus
      const options =
        regions?.reduce((carry, region) => {
          for (const country of region.countries) {
            carry.set(country.iso_2, translateCountry(country.display_name));
          }

          return carry;
        }, new Map<string, string>()) ?? new Map<string, string>();

      return Array.from(options.entries()).map(([value, label]) => ({
        value,
        label,
      }));
    }, [regions]);

    const hasError = get(errors, name);

    // Fix default;
    const { control, getValues } = useFormContext<AddressInterface>();
    const value = useWatch({ control, name: name as keyof AddressInterface });
    let defaultValue = props.value;
    if (defaultValue === undefined) {
      defaultValue = (getValues(name as keyof AddressInterface) ?? value) as string;
    }

    return (
      <div>
        <NativeSelect
          ref={innerRef}
          placeholder={placeholder}
          className={clsx(
            "border-2 focus:outline-none font-semibold border-gray-500 bg-gray-100 text-gray-700",
            {
              "border-red-500 bg-gray-100 text-red-500 placeholder-red-500": hasError,
            },
            className,
          )}
          name={name}
          {...props}
          value={defaultValue}
        >
          {countryOptions.map(({ value, label }, index) => (
            <option key={index} value={value} className="text-gray-700">
              {label}
            </option>
          ))}
        </NativeSelect>
        {hasError && (
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => {
              return (
                <div className="text-red-500 font-semibold">
                  <span>{message}</span>
                </div>
              );
            }}
          />
        )}
      </div>
    );
  },
);

CountrySelect.displayName = "CountrySelect";

export default CountrySelect;
