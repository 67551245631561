import type { AriaPopoverProps } from "@react-aria/overlays";
import { DismissButton, Overlay, usePopover } from "@react-aria/overlays";
import * as React from "react";
import { useEffect } from "react";
import type { OverlayTriggerState } from "react-stately";

interface PopoverProps extends Omit<AriaPopoverProps, "popoverRef"> {
  children: React.ReactNode;
  state: OverlayTriggerState;
  className?: string;
  popoverRef?: React.RefObject<HTMLDivElement>;
}

export default function Popover(props: PopoverProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  const { popoverRef = ref, state, children, className, isNonModal } = props;

  useEffect(() => {
    if (props.state.isOpen && popoverRef.current && props.triggerRef.current) {
      // https://github.com/facebook/react/issues/29703 https://github.com/facebook/react/issues/29106
      // eslint-disable-next-line react-compiler/react-compiler
      popoverRef.current.style.width = `${props.triggerRef.current?.getBoundingClientRect().width}px`;
    }
  }, [popoverRef, props.state.isOpen, props.triggerRef]);
  const { popoverProps, underlayProps } = usePopover(
    {
      ...props,
      popoverRef,
    },
    state,
  );

  return (
    <Overlay>
      {!isNonModal && <div {...underlayProps} className="fixed inset-0" />}
      <div {...popoverProps} ref={popoverRef} className={`z-10 shadow-lg border border-gray-500 bg-white mt-1 ${className}`}>
        {!isNonModal && <DismissButton onDismiss={state.close} />}
        {children}
        <DismissButton onDismiss={state.close} />
      </div>
    </Overlay>
  );
}
