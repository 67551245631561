import { Address } from "@medusajs/medusa";
import { useCart, useUpdateCart } from "medusa-react";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { AddressInterface } from "@/components/pages/account/AddAddress";
import medusaRequest from "@/data/medusa-fetch";
import { useAccount } from "@/lib/contexts/account-context";
import { useCheckout } from "@/lib/contexts/checkout-context";
import { CHECKOUT_ADDRESS, useSideModal } from "@/lib/contexts/sidemodal-context";
import { shippingAddressPayload } from "@/lib/contexts/store-context";
import { handleError } from "@/lib/util/handle-error";
import { AddressModalFlyout } from "@/modules/checkout/components/Address/Modal/flyout";
import { SelectAddressAction } from "@/modules/checkout/components/Address/Modal/select-action";
import LinkButton from "@/modules/common/components/link-button";

import { handleAddressValues } from "./BillingAddress/GuestViewBillingAddress";

interface AddressModalInterface {
  selectBillingAddress?: boolean;
}

function convertDateFormat(input: string | undefined) {
  const regex = /^\d{1,2}\.\d{1,2}\.\d{4}$/;
  if (!input || !regex.test(input)) {
    return undefined;
  }

  // Teile das Datum in Tag, Monat und Jahr
  const [day, month, year] = input.split(".");

  // Füge führende Nullen zu Tag und Monat hinzu, falls notwendig
  const paddedDay = day.padStart(2, "0");
  const paddedMonth = month.padStart(2, "0");

  // Gib das Datum im neuen Format zurück
  return `${year}-${paddedMonth}-${paddedDay}`;
}

export const addressPayload = (data: AddressInterface) => {
  let address1 = data.address_1;
  let address2 = data.address_2;
  let address3 = undefined;

  if (data.delivery_type === "at_packing_station") {
    address1 = "Packstation";
    address2 = data.paket_station_number;
    address3 = data.post_number;
  }
  if (data.delivery_type === "at_post_office") {
    address1 = "Postfiliale";
    address2 = data.paket_station_number;
    address3 = data.post_number;
  }

  return {
    first_name: data.first_name,
    last_name: data.last_name,
    company: data.company || "",
    address_1: address1,
    address_2: address2 || "",
    city: data.city,
    country_code: data.country_code,
    province: data.province || "",
    postal_code: data.postal_code,
    phone: data.phone || "",
    metadata: {
      address_3: address3,
      delivery_type: data.delivery_type,
      birthday: convertDateFormat(data.birthday),
      salutation: data.salutation,
      paket_station_number: data.paket_station_number,
      post_number: data.post_number,
      invoice_address: data.invoice_address,
    },
  };
};

export const AddressModal = ({ selectBillingAddress }: AddressModalInterface) => {
  const {
    modal: { isShow, name },
    setModal,
    close,
  } = useSideModal();
  const isOpen = name === CHECKOUT_ADDRESS && isShow;
  const { customer } = useAccount();
  const [checked, setChecked] = useState<number>(0);
  const [isNewAddress, setNewAddress] = useState<boolean>(false);
  const [isEditAddress, setEditAddress] = useState<boolean>(false);
  const { cart } = useCheckout();
  const { setCart } = useCart();
  const updateCart = useUpdateCart(cart?.id ?? "");
  const [selectedAddress, setSelectedAddress] = useState<AddressInterface | undefined>(undefined);
  const modalForm = useForm<AddressInterface>({ defaultValues: { delivery_type: "home", country_code: "de" } });
  const { setValue } = modalForm;
  const addresses = customer?.shipping_addresses || [];

  useEffect(() => {
    if (!customer) {
      selectBillingAddress
        ? cart?.billing_address && handleAddressValues(setValue, cart.billing_address)
        : cart?.shipping_address && handleAddressValues(setValue, cart.shipping_address);
    }

    if (!isOpen || addresses.length > 0) {
      return;
    }
    customer && setNewAddress(true);
  }, [setNewAddress, isOpen, addresses.length]);

  const onSelect = useCallback(
    (selectedAddressItem: AddressInterface) => {
      const args = selectBillingAddress
        ? { billing_address: shippingAddressPayload(selectedAddressItem as Address) }
        : { shipping_address: shippingAddressPayload(selectedAddressItem as Address) };
      updateCart.mutate(args, {
        onSuccess: async ({ cart }) => {
          if (cart.metadata?.shippingCostUpdateRequired) {
            const cartValidation = await medusaRequest("POST", `/carts/${cart.id}/validate-shipping`);
            if (cartValidation.ok) {
              setCart(cartValidation.body.cart);
            } else {
              handleError(new Error(`Error validating shipping for cart ${cart.id}`));
            }
          } else {
            setCart(cart);
          }

          close();
        },
      });
    },
    [selectBillingAddress, updateCart, setCart, close],
  );

  const onClose = useCallback(() => {
    setModal({ isShow: false, name: "" });
    setChecked(0);
    setNewAddress(false);
    setEditAddress(false);
    setSelectedAddress(undefined);
    modalForm.reset();
  }, [setChecked, setNewAddress, setEditAddress]);

  const BackLink = (
    <LinkButton
      className="text-lg mb-3"
      onClick={() => {
        setChecked(0);
        setNewAddress(false);
        setEditAddress(false);
        modalForm.reset();
      }}
    >
      Zurück
    </LinkButton>
  );

  return (
    <AddressModalFlyout
      onSelect={onSelect}
      isCreate={isNewAddress}
      isEdit={isEditAddress || !customer}
      onClose={onClose}
      BackLink={BackLink}
      selectBillingAddress={selectBillingAddress}
      modalForm={modalForm}
    >
      <SelectAddressAction
        onSelect={onSelect}
        onClose={onClose}
        BackLink={BackLink}
        selectBillingAddress={selectBillingAddress}
        modalForm={modalForm}
        checked={checked}
        setChecked={setChecked}
        setSelectedAddress={setSelectedAddress}
        setNewAddress={setNewAddress}
        setEditAddress={setEditAddress}
        selectedAddress={selectedAddress}
      />
    </AddressModalFlyout>
  );
};
