"use client";

import { Cart as CartType } from "@medusajs/medusa";
import * as Sentry from "@sentry/nextjs";
import { AxiosError } from "axios";
import clsx from "clsx";
import { useCart } from "medusa-react";
import Link from "next/link";
import React, { useCallback, useEffect, useState } from "react";

import { Cart } from "@/components/pages/checkout/Cart";
import { PaymentOverviewSkeleton } from "@/components/Skeleton/Checkout/PaymentListSkeleton";
import { useCheckout } from "@/lib/contexts/checkout-context";
import { useLoadingRouter } from "@/lib/contexts/loading-context";
import { CHECKOUT_ADDRESS, useSideModal } from "@/lib/contexts/sidemodal-context";
import { useStore } from "@/lib/contexts/store-context";
import { AddressModal } from "@/modules/checkout/components/Address/AddressModal";
import { AddressTitle, BillingAddress } from "@/modules/checkout/components/Address/BillingAddress/ViewBillingAddress";
import { PaymentInfoMap } from "@/modules/checkout/components/Payments/payment-container";
import Button from "@/modules/common/components/button";

import { PaymentConfirmButton } from "./components/Payments";

export const OverviewSection = ({
  name,
  children,
  edit,
  isDetailPage = false,
}: {
  name: string;
  children: React.ReactNode;
  edit?: React.ReactNode;
  isDetailPage?: boolean;
}) => {
  return (
    <div className={clsx({ "font-bold": !isDetailPage })}>
      {edit}
      <p className="mb-3 font-extrabold">{name}</p>

      {children}
      {!isDetailPage && <hr className="mt-10 mb-10 h-0.5 border-t-0 bg-black opacity-100" />}
    </div>
  );
};
export const PaymentInformation = ({ cart, isDetailPage = false }: { cart: CartType; isDetailPage?: boolean }) => {
  const name = "Zahlart";

  if (!cart) {
    return (
      <OverviewSection name={name} isDetailPage={isDetailPage}>
        <PaymentOverviewSkeleton />
      </OverviewSection>
    );
  }

  if (cart && !cart.payment_session) {
    return (
      <OverviewSection name={name} isDetailPage={isDetailPage}>
        <p>Keine Zahlart ausgewählt</p>
      </OverviewSection>
    );
  }

  const payment = PaymentInfoMap[cart.payment_session?.provider_id ?? ""];

  return (
    <OverviewSection name={name} isDetailPage={isDetailPage}>
      <p className={clsx({ "font-semibold": isDetailPage })}>{payment?.title}</p>
    </OverviewSection>
  );
};

export interface ConfirmData {
  accept_contact?: boolean;
}
export const useOnConfirm = () => {
  const { cart, setCart, updateCart } = useCart();
  return useCallback(
    async (confirm: ConfirmData) => {
      // TODO: Für evtl mehrere Daten abstrahieren
      if ((cart?.metadata?.accept_contact ?? false) !== (confirm.accept_contact ?? false)) {
        const { cart: newCart } = await updateCart.mutateAsync({ context: { accept_contact: confirm.accept_contact } });
        setCart(newCart);
        return newCart;
      }
      return cart;
    },
    [cart, setCart, updateCart],
  );
};

export const SubmitButton = ({ valid, confirm, failedMessage }: { valid: boolean; confirm: ConfirmData; failedMessage?: string }) => {
  const [error, setError] = useState<null | string>(null);
  const [submitting, setSubmitting] = useState(false);
  const { completeCheckout } = useCart();
  const { readyToComplete } = useCheckout();
  const { resetCart } = useStore();
  const router = useLoadingRouter();
  const onConfirm = useOnConfirm();

  const handleComplete = async () => {
    setSubmitting(true);
    try {
      await onConfirm(confirm);

      const result = await completeCheckout.mutateAsync(undefined);
      if (result.type === "order") {
        resetCart(true);
        router.push(`/order/confirmed/${result.data.id}`, {
          scroll: true,
        });
        return;
      }
      if (result.type === "cart") {
        const { redirectUrl } = (result.data.payment_session?.data || {}) as { redirectUrl?: string };
        if (
          redirectUrl &&
          (
            result as typeof result & {
              payment_status: string;
            }
          ).payment_status === "requires_more"
        ) {
          window.location.href = redirectUrl;
          return;
        }
      }
      throw new Error("Unexpected completeCheckoutResult");
    } catch (_error) {
      const errorMessage = (_error as AxiosError).response?.data?.message ?? _error;

      Sentry.captureException(_error);
      setError(failedMessage ?? (errorMessage as Error).message ?? errorMessage.toString());
      console.error("Error during checkout", _error);
      setSubmitting(false);
      throw _error;
    }
  };

  return (
    <div className="w-full">
      {error ? <div className="w-full text-red-500">{error}</div> : null}
      <Button
        className="p-5 font-light text-lg text-center uppercase w-full"
        disabled={!readyToComplete || !valid || submitting}
        variant="active"
        onClick={handleComplete}
      >
        Jetzt kostenpflichtig bestellen
      </Button>
    </div>
  );
};

export default function Overview() {
  const { cart } = useCart();
  const [isShippingEdit, setShippingEdit] = useState<boolean>(false);
  const [isBillingEdit, setBillingEdit] = useState<boolean>(false);
  const {
    modal: { isShow, name },
  } = useSideModal();

  const isOpen = name === CHECKOUT_ADDRESS && isShow;
  const isValid: boolean = !!cart?.billing_address_id && !!cart?.shipping_address_id && !!cart?.payment_session;

  useEffect(() => {
    if (!isOpen) {
      setShippingEdit(false);
      setBillingEdit(false);
    }
  }, [isOpen]);

  return (
    <>
      <h1 className="text-center text-4xl uppercase">Bestellübersicht</h1>
      <hr className="mt-7 mb-14 h-0.5 border-t-0 bg-black opacity-100" />

      <div className="mb-16 block lg:hidden">
        <Cart showPaymentMethods={false} noTitle={true} />
      </div>
      <hr className="mt-16 mb-14 h-0.5 border-t-0 bg-black opacity-100 lg:hidden" />

      <div className="flex justify-between">
        <AddressTitle isEdit={isShippingEdit} setEdit={setShippingEdit} cart={cart as CartType} title="Lieferadresse" />
      </div>

      <div className="mb-20">
        <BillingAddress cart={cart as CartType} isLoading={false} isShippingAddress />
      </div>

      <hr className="mt-7 mb-14 h-0.5 border-t-0 bg-black opacity-100" />

      <div className="flex justify-between">
        <AddressTitle isEdit={isBillingEdit} setEdit={setBillingEdit} cart={cart as CartType} title="Rechnungsadresse" />
        <AddressModal selectBillingAddress={isBillingEdit ? true : isShippingEdit && false} />
      </div>

      <div className="mb-20">
        <BillingAddress cart={cart as CartType} isLoading={false} />
      </div>

      <hr className="mt-7 mb-14 h-0.5 border-t-0 bg-black opacity-100" />

      <PaymentInformation cart={cart as CartType} />

      {/*<CheckboxInput
        id={"checkout-contact"}
        label="Ich stimme zu, dass CHANNEL21 mich zu gebener Zeit kontaktieren kann um mir eine eMail vom Anbieter „Echte Bewertungen“ schicken kann."
        className="font-bold text-lg relative bottom-2"
        checked={checked}
        onChange={() => setChecked(!checked)}
      />*/}

      <p className="mt-10 font-bold mb-10">
        Mit Ihrer Bestellung erklären Sie sich mit unseren{" "}
        <Link href="/datenschutz" target="_blank">
          Datenschutzbestimmungen
        </Link>
        ,{" "}
        <Link href="/agb" target="_blank">
          AGB
        </Link>{" "}
        und{" "}
        <Link href="/widerruf" target="_blank">
          Widerrufsbestimmungen
        </Link>{" "}
        einverstanden.
      </p>

      <PaymentConfirmButton cart={cart as CartType} valid={isValid} confirm={{}} />
    </>
  );
}
