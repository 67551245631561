import React from "react";
import { FormProvider } from "react-hook-form";

import { RequiredLabel } from "@/components/Form/required-label";
import { Flyout } from "@/components/Modal/Flyout";
import { AddressInterface } from "@/components/pages/account/AddAddress";
import { medusaClient } from "@/lib/config";
import { useAccount } from "@/lib/contexts/account-context";
import { CHECKOUT_ADDRESS, useSideModal } from "@/lib/contexts/sidemodal-context";
import { AddressForm, validateFormData } from "@/modules/checkout/components/Address/AddressForm";
import { addressPayload } from "@/modules/checkout/components/Address/AddressModal";
import { BaseActionInterface } from "@/modules/checkout/components/Address/Modal/base-action";
import Button from "@/modules/common/components/button";

interface CreateAddressInterface extends BaseActionInterface {
  onSelect: (address: AddressInterface) => void;
}

export const CreateAddress = ({ onClose, BackLink, selectBillingAddress, onSelect, modalForm }: CreateAddressInterface) => {
  const {
    modal: { isShow, name },
  } = useSideModal();
  const { refetchCustomer: refetch } = useAccount();
  const isOpen = name === CHECKOUT_ADDRESS && isShow;
  const { handleSubmit, reset } = modalForm;

  const onSubmit = handleSubmit(async (data: AddressInterface) => {
    if (!(await validateFormData(modalForm, data))) {
      return;
    }
    const address = addressPayload(data);
    address.metadata.invoice_address = selectBillingAddress ?? false;

    medusaClient.customers.addresses.addAddress({ address: address }).then(() => {
      refetch();
      reset();
      onSelect(address);
      onClose();
    });
  });

  const submitButton = (
    <Button className="p-5 font-light text-lg text-center uppercase w-full" variant="dark" onClick={onSubmit}>
      Übernehmen
    </Button>
  );

  return (
    <Flyout open={isOpen} right={true} onClose={onClose} button={submitButton}>
      <div className="text-lg pb-7">
        {BackLink}
        <p className="font-extrabold text-center text-lg mb-3">Neue Adresse hinzufügen</p>

        <hr className="mt-7 mb-12 h-0.5 border-t-0 bg-black opacity-100" />
        <FormProvider {...modalForm}>
          <AddressForm isBillingAddress={selectBillingAddress}>
            <RequiredLabel />
          </AddressForm>
        </FormProvider>
      </div>
    </Flyout>
  );
};
