import { Cart } from "@medusajs/medusa";
import React, { useLayoutEffect } from "react";

import { AddressSkeleton, AddressTitleSkeleton } from "@/components/Skeleton/Checkout/AddressSkeleton";
import { useCheckout } from "@/lib/contexts/checkout-context";
import { CHECKOUT_ADDRESS, useSideModal } from "@/lib/contexts/sidemodal-context";

interface BillingAddressTitleInterface {
  isEdit?: boolean;
  isLoading?: boolean;
  setEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  cart: Cart;
  title: string;
}

export const AddressTitle = ({ setEdit, cart, title, isLoading = false }: BillingAddressTitleInterface) => {
  const { isAddressChecked } = useCheckout();
  const address = cart?.billing_address ?? cart?.shipping_address;
  const { setModal } = useSideModal();

  useLayoutEffect(() => {
    if (!cart?.items.length || address || !isAddressChecked) {
      return;
    }

    setEdit && setEdit(true);
    setModal({
      name: CHECKOUT_ADDRESS,
      isShow: true,
    });
  }, [address, cart, isAddressChecked, setEdit, setModal]);

  if (isLoading) {
    return <AddressTitleSkeleton />;
  }

  const onClick = () => {
    setEdit && setEdit(true);
    setModal({
      name: CHECKOUT_ADDRESS,
      isShow: true,
    });
  };

  return (
    <>
      <h2 className="font-extrabold">{title}</h2>
      {setEdit && (
        <button onClick={() => onClick()} className="underline font-extrabold text-xs">
          Bearbeiten
        </button>
      )}
    </>
  );
};

export const BillingAddress = ({
  cart,
  isLoading,
  isShippingAddress,
  hideEmail,
}: {
  cart: Cart;
  isLoading: boolean;
  isShippingAddress?: boolean;
  hideEmail?: boolean;
}) => {
  let address = cart?.shipping_address;

  if (!isShippingAddress && cart?.billing_address_id) {
    address = cart?.billing_address;
  }

  if (isShippingAddress) {
    address = cart?.shipping_address;
  }

  if (!address?.last_name && cart && !isLoading) {
    return <p>Keine Adresse vorhanden.</p>;
  }

  return (
    <div className="font-semibold text-base">
      {cart && address && !isLoading ? (
        <div className="flex items-start gap-x-8">
          <div className="flex items-start justify-between w-full">
            <div className="flex flex-col">
              <span>
                {address.first_name} {address.last_name}
              </span>
              {address?.company && <span>{address.company}</span>}
              <span>
                {address.address_1} {address.address_2}
              </span>
              <span>
                {address.postal_code} {address.city}
              </span>
              <div className="mt-4 flex flex-col">
                <span>{address.phone}</span>
                {!hideEmail && <span>E-Mail-Adresse: {cart.email}</span>}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <AddressSkeleton />
      )}
    </div>
  );
};
