import { Address } from "@medusajs/medusa";
import React from "react";
import { UseFormReturn } from "react-hook-form";

import { RadioInput } from "@/components/Form/Input/RadioInput";
import { AddressInterface } from "@/components/pages/account/AddAddress";
import { useAccount } from "@/lib/contexts/account-context";
import { handleAddressValues } from "@/modules/checkout/components/Address/BillingAddress/GuestViewBillingAddress";

export interface BaseActionInterface {
  onClose: () => void;
  BackLink: React.JSX.Element;
  selectBillingAddress: boolean | undefined;
  modalForm: UseFormReturn<AddressInterface>;
}

export interface AddressesInterface {
  checked: number;
  modalForm: UseFormReturn<AddressInterface>;
  setChecked: React.Dispatch<React.SetStateAction<number>>;
  setSelectedAddress: React.Dispatch<React.SetStateAction<AddressInterface | undefined>>;
  setNewAddress: React.Dispatch<React.SetStateAction<boolean>>;
  setEditAddress: React.Dispatch<React.SetStateAction<boolean>>;
  selectedAddress: AddressInterface | undefined;
  selectBillingAddress?: boolean;
}

export const Addresses = ({
  checked,
  selectedAddress,
  selectBillingAddress,
  setChecked,
  setSelectedAddress,
  setEditAddress,
  setNewAddress,
  modalForm,
}: AddressesInterface) => {
  const { customer } = useAccount();
  const addresses = customer?.shipping_addresses ?? [];
  const { setValue } = modalForm;

  const handleSelect = (id: string, edit = false) => {
    setChecked(1);
    setNewAddress(false);

    const savedAddress = addresses.find((a) => a.id === id);
    setSelectedAddress(savedAddress as AddressInterface);

    if (edit) {
      if (savedAddress) {
        handleAddressValues(setValue, savedAddress);
      }
      setEditAddress(true);
    }
  };

  return (
    <>
      {addresses.map((address, index) => {
        const isSelected = checked !== 2 && selectedAddress?.id === address.id;

        if (selectBillingAddress) {
          if (address.metadata.invoice_address) {
            return <AddressRender address={address} index={index} isSelected={isSelected} handleSelect={handleSelect} key={`address-${address.id}`} />;
          }
        } else {
          if (!address.metadata.invoice_address) {
            return <AddressRender address={address} index={index} isSelected={isSelected} handleSelect={handleSelect} key={`address-${address.id}`} />;
          }
        }

        return <></>;
      })}
    </>
  );
};

const AddressComponent = ({
  children,
  id,
  isSelected,
  handleSelect,
}: {
  children: React.ReactNode;
  id: string;
  isSelected: boolean;
  handleSelect: (id: string, edit?: boolean) => void;
}) => {
  return (
    <>
      <hr className="mt-7 mb-7 h-0.5 border-t-0 bg-black opacity-100" />

      <RadioInput name="radio" checked={isSelected} onChange={() => handleSelect(id)} className={"relative"}>
        <div className="text-lg font-bold ms-8 bottom-2 relative">
          {children}

          <div className="mt-7">
            <p className="underline cursor-pointer text-sm" onClick={() => handleSelect(id, true)}>
              Bearbeiten
            </p>
          </div>
        </div>
      </RadioInput>
    </>
  );
};

const AddressRender = ({
  address,
  index,
  isSelected,
  handleSelect,
}: {
  address: Address;
  index: number;
  isSelected: boolean;
  handleSelect: (id: string, edit?: boolean) => void;
}) => {
  return (
    <AddressComponent id={address.id} key={index} isSelected={isSelected} handleSelect={handleSelect}>
      <p>
        {address.first_name} {address.last_name}
      </p>
      {address?.company && <p>{address.company}</p>}
      <p>
        {address.address_1} {address.address_2}
      </p>
      <p>
        {address.postal_code} {address.city}
      </p>
    </AddressComponent>
  );
};
