import clsx from "clsx";
import React from "react";
import { useFormContext, UseFormReturn, useWatch } from "react-hook-form";
import { Item } from "react-stately";

import { CheckboxInput } from "@/components/Form/Input/CheckboxInput";
import { AddressInterface } from "@/components/pages/account/AddAddress";
import { validateAddress } from "@/lib/actions/address/validate";
import CountrySelect from "@/modules/checkout/components/country-select";
import SalutationSelect from "@/modules/checkout/components/salutation-select";
import InputAutoSuggest from "@/modules/common/components/auto-suggest";
import Input from "@/modules/common/components/input";

import { useAddressSuggestions } from "./useAddressSuggestions";

interface NewAddressInterface {
  isBillingAddress?: boolean;
  children?: React.ReactNode;
}

export async function validateFormData(form: UseFormReturn<AddressInterface>, data: AddressInterface): Promise<boolean> {
  const { setError, setValue } = form;
  if (!data.confirm_invalid_address && data.delivery_type !== "at_packing_station" && data.delivery_type !== "at_post_office") {
    const validation = await validateAddress({
      street: data.address_1,
      postalCode: data.postal_code,
      city: data.city,
    });
    if (validation.valid === false) {
      setError("root.validation", {
        type: "address",
        message: "Die Adresse scheint ungültig zu sein. Bitte überprüfen Sie Ihre Eingaben.",
      });
      return false;
    } else {
      setValue("confirm_invalid_address", false);
    }
  }
  return true;
}

export const AddressForm = ({ isBillingAddress, children }: NewAddressInterface) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<AddressInterface>();
  const deliveryType = useWatch({
    control,
    name: "delivery_type",
  });
  const { suggestions, onSelectionChange } = useAddressSuggestions();

  return (
    <div className="mb-16">
      {!isBillingAddress && (
        <>
          <div className="flex">
            <label className="mb-3">
              <input {...register("delivery_type")} type="radio" value="home" id="station-deliver" className="w-4 h-4 accent-black cursor-pointer" />
              <span className="font-bold text-lg ml-4">Lieferung nach Hause</span>
            </label>
          </div>

          <div className="flex">
            <label className="mb-3">
              <input
                {...register("delivery_type")}
                type="radio"
                value="at_packing_station"
                id="station-deliver"
                className="w-4 h-4 accent-black cursor-pointer"
              />
              <span className="font-bold text-lg ml-4">An Packstation senden</span>
            </label>
          </div>

          <div className="flex">
            <label className="mb-3">
              <input {...register("delivery_type")} type="radio" value="at_post_office" id="post-deliver" className="w-4 h-4 accent-black cursor-pointer" />
              <span className="font-bold text-lg ml-4">An Postfiliale senden</span>
            </label>
          </div>
        </>
      )}

      <div className="grid grid-cols-1 gap-y-4">
        <CountrySelect
          {...register("country_code", {
            required: "Bitte wähle ein Land",
          })}
          placeholder="Land*"
          autoComplete="country"
          className="pr-10 border-gray-500 bg-gray-100 text-gray-700 focus:outline-none"
          errors={errors}
        />
        <Input
          label="Firma"
          {...register("company")}
          autoComplete="organization"
          className="border-gray-500 bg-gray-100 text-gray-700 w-full"
          errors={errors}
        />
        <SalutationSelect
          {...register("salutation", {
            required: "Wähle eine Anrede",
          })}
          className="pr-10 border-gray-500 bg-gray-100 text-gray-700 focus:outline-none"
          autoComplete="sex"
          placeholder="Anrede*"
          errors={errors}
        />
        <div className="grid grid-cols-12 gap-x-4">
          <div className="col-span-12 lg:col-span-6">
            <Input
              label="Vorname*"
              {...register("first_name", {
                required: "Bitte gebe deinen Vornamen an",
              })}
              className="border-gray-500 bg-gray-100 text-gray-700 w-full"
              autoComplete="given-name"
              errors={errors}
            />
          </div>
          <div className="col-span-12 lg:col-span-6">
            <Input
              label="Nachname*"
              {...register("last_name", {
                required: "Bitte gebe deinen Nachnamen an",
              })}
              className="border-gray-500 bg-gray-100 text-gray-700 w-full"
              autoComplete="family-name"
              errors={errors}
            />
          </div>
        </div>
        {deliveryType === "at_post_office" || deliveryType === "at_packing_station" ? (
          <>
            <Input
              label="PostNr.*"
              {...register("post_number", {
                required: "Bitte geben Sie ihre Postnummer an",
              })}
              className="border-gray-500 bg-gray-100 text-gray-700 w-full"
              errors={errors}
            />
            {deliveryType === "at_post_office" ? (
              <Input
                label="Postfiliale Nr.*"
                {...register("paket_station_number", {
                  required: "Bitte gebe eine Postfilial-Nummer an",
                })}
                className="border-gray-500 bg-gray-100 text-gray-700 w-full"
                errors={errors}
              />
            ) : (
              <Input
                label="Packstation Nr.*"
                {...register("paket_station_number", {
                  required: "Bitte gebe eine Paketstation Nummer an",
                })}
                className="border-gray-500 bg-gray-100 text-gray-700 w-full"
                errors={errors}
              />
            )}
          </>
        ) : (
          <>
            <div className="grid grid-cols-12 gap-x-4">
              <div className="col-span-12 pb-4 xl:col-span-8 xl:pb-0">
                <InputAutoSuggest
                  label="Straße*"
                  {...register("address_1", {
                    required: "Bitte gebe deinen Straße an",
                  })}
                  className="border-gray-500 bg-gray-100 text-gray-700 w-full"
                  autoComplete="address-line1"
                  errors={errors}
                  items={suggestions.street.items}
                  // inputValue={suggestions.street.filterText}
                  // onInputChange={suggestions.street.setFilterText}
                  onSelectionChange={(key) => onSelectionChange("street", key)}
                >
                  {(item) => (
                    <Item key={item.id} textValue={item.street}>
                      {item.street}
                      {item.postalCode && item.city && (
                        <>
                          <br />
                          {item.postalCode} {item.city}
                        </>
                      )}
                    </Item>
                  )}
                </InputAutoSuggest>
              </div>
              <div className="col-span-12 xl:col-span-4">
                <Input
                  label="Hausnummer*"
                  {...register("address_2", {
                    required: "Bitte gebe deine Hausnummer an",
                  })}
                  className="border-gray-500 bg-gray-100 text-gray-700 w-full"
                  autoComplete="address-line2"
                  errors={errors}
                />
              </div>
            </div>
          </>
        )}
        <div className="grid grid-cols-12 gap-x-4">
          <div className="col-span-12 pb-4 xl:col-span-4 xl:pb-0">
            <InputAutoSuggest
              label="PLZ*"
              className="border-gray-500 bg-gray-100 text-gray-700 w-full"
              {...register("postal_code", {
                required: "Bitte gebe deinen Postleitzahl an",
              })}
              autoComplete="postal-code"
              errors={errors}
              items={suggestions.postalCode.items}
              // inputValue={suggestions.postalCode.filterText}
              // onInputChange={suggestions.postalCode.setFilterText}
              onSelectionChange={(key) => onSelectionChange("postalCode", key)}
            >
              {(item) => (
                <Item key={item.id} textValue={item.postalCode}>
                  {item.postalCode} {item.city}
                </Item>
              )}
            </InputAutoSuggest>
          </div>
          <div className="col-span-12 xl:col-span-8">
            <InputAutoSuggest
              label="Ort*"
              {...register("city", {
                required: "Bitte gebe deine Stadt an",
              })}
              className="border-gray-500 bg-gray-100 text-gray-700 w-full"
              autoComplete="address-level2"
              errors={errors}
              items={suggestions.city.items}
              // inputValue={suggestions.city.filterText}
              // onInputChange={suggestions.city.setFilterText}
              onSelectionChange={(key) => onSelectionChange("city", key)}
            >
              {(item) => (
                <Item key={item.id} textValue={item.city}>
                  {item.city}
                </Item>
              )}
            </InputAutoSuggest>
          </div>
        </div>
        <Input
          label="Geburtstag*"
          {...register("birthday", {
            required: "Bitte gebe deinen Geburtstag an",
            pattern: { value: /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/, message: "Bitte ein Datum im Format TT.MM.JJJJ eingeben" },
          })}
          className="border-gray-500 bg-gray-100 text-gray-700 w-full"
          onChange={(event) => {
            const pattern = /^(0[1-9]|[12][0-9]|3[01])\.?(0[1-9]|1[0-2])\.?(\d{4})$/;
            const matches = event.target.value.match(pattern);

            if (matches) {
              event.target.value = `${matches?.at(1)}.${matches?.at(2)}.${matches?.at(3)}`;
            }
          }}
          autoComplete="bday"
          errors={errors}
        />
        <Input label="Telefonnummer" {...register("phone")} autoComplete="tel" errors={errors} className="w-full" />

        <div className={clsx({ hidden: !errors.root?.validation })}>
          <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
            <span>{errors.root?.validation.message}</span>
          </div>
          <CheckboxInput id={"confirm_invalid_address"} label={"Die eingegebene Adresse trotz Fehler übernehmen"} {...register("confirm_invalid_address")} />
        </div>

        {children}
      </div>
    </div>
  );
};
