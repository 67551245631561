import { PaymentSession } from "@medusajs/medusa";
import { useCart, useUpdatePaymentSession } from "medusa-react";
import Script from "next/script";
import React, { useEffect, useRef, useState } from "react";

import { SetSubmitCallback } from "./index";

interface PaymentData extends Record<string, unknown> {
  mode: "test" | "live";
  merchantId: string;
  orderId: string;
}

type PayEngineWidgetReference = string;

type OptionalParameters = {
  initCallback: (error: string | null, result: PayEngineWidgetReference) => void;
  products?: ("sepa" | "creditcard")[];
  layout?: "dropdown" | "full-list";
  language?: "en" | "de";
  customStyleId?: string;
  hidePayButton?: boolean;
  hideTitleIcons?: boolean;
  redirectUser?: boolean;
  optionalFields?: {
    creditCard?: {
      cardHolder?: boolean;
    };
    sepa?: {
      accountHolder?: boolean;
      bic?: boolean;
      bankName?: boolean;
    };
    ratepayDirectDebit?: {
      accountHolder?: boolean;
      bic?: boolean;
    };
    ratepayInstallment?: {
      accountHolder?: boolean;
      bic?: boolean;
    };
    autoResizeHeight?: boolean;
  };
  deviceIdent?: boolean;
  paypalRecurringBillingAgreement?: boolean;
  skipFinalResultView?: boolean;
};
declare global {
  // https://developer.nexigroup.com/payengine/en-EU/docs/implement-an-inline-widget/
  const PayEngineWidget: {
    initAsInlineComponent(
      container: string | HTMLElement,
      publishableKey: string,
      transactionIntentId: string,
      optionalParameters: OptionalParameters,
      resultCallback: (error: string | null, result: { paymentId: string }) => void,
    ): void;
    initAsInlineComponentPi(
      container: string | HTMLElement,
      publishableKey: string,
      optionalParameters: OptionalParameters,
      resultCallback: (error: string | null, result: { paymentId: string }) => void,
    ): void;
    pay(widgetReference: PayEngineWidgetReference): void;
    validate(
      widgetReference: PayEngineWidgetReference,
      callback: (
        error: {
          cardNumber?: boolean;
          expiry?: boolean;
          verification?: boolean;
          cardHolder?: boolean;
          email?: boolean;
        } | null,
        result: boolean,
      ) => void,
    ): void;
    addValidationListener(widgetReference: PayEngineWidgetReference, listener: (result: unknown) => void): void;
    removeValidationListener(widgetReference: PayEngineWidgetReference, listener: (result: unknown) => void): void;
  };
}

export const NexiPayengineWidget = ({
  paymentSession,
  setSubmitCallback,
  handleComplete,
}: {
  paymentSession: PaymentSession;
  setSubmitCallback: SetSubmitCallback;
  handleComplete: () => void;
}) => {
  const [checkoutLoaded, setCheckoutLoaded] = useState(false);
  // const [id, setId] = useState(0);
  // const idInitialized = useRef(-1);
  const widgetReference = useRef<PayEngineWidgetReference | null>(null);
  const data = paymentSession.data as PaymentData;
  const payPromiseRef = useRef<{ resolve: (success: true) => void; reject: (error: string) => void } | null>(null);
  const { cart } = useCart();
  const updatePaymentSession = useUpdatePaymentSession(cart?.id || "");

  const widgetSrc =
    data.mode === "test" ? "https://pptest.payengine.de/widgetjs/payengine.widget.min.js" : "https://pp.payengine.de/widgetjs/payengine.widget.min.js";

  // useEffect(() => {
  //   setId((id) => id + 1);
  // }, [paymentSession.data.paymentId]);
  useEffect(() => {
    setSubmitCallback(async () => {
      if (!widgetReference.current) {
        throw new Error("Die Zahlungsart wurde nicht initialisiert. Bitte versuchen Sie es erneut.");
      }

      await new Promise((resolve, reject) => {
        PayEngineWidget.validate(widgetReference.current!, (error, result) => {
          if (error && !(error.cardNumber && error.expiry && error.verification && error.cardHolder)) {
            reject(error);
          } else {
            resolve(result);
          }
        });
      });

      return new Promise<boolean>((resolve, reject) => {
        payPromiseRef.current = { resolve, reject };
        PayEngineWidget.pay(widgetReference.current!);
      });
    });
    return () => setSubmitCallback(null);
  }, [setSubmitCallback]);

  useEffect(() => {
    if (!checkoutLoaded) {
      return;
    }
    PayEngineWidget.initAsInlineComponentPi(
      "nexi-payengine-container-div",
      data.merchantId,
      {
        initCallback: (error, result) => {
          if (error) {
            console.error(error);
          } else {
            widgetReference.current = result;
          }
        },
        products: ["creditcard"],
        optionalFields: {
          autoResizeHeight: true,
        },
        language: "de",
        hidePayButton: true,
      },
      (error, result) => {
        (async () => {
          if (error) {
            payPromiseRef.current?.reject(error);
            payPromiseRef.current = null;
          } else {
            await updatePaymentSession.mutateAsync({
              provider_id: paymentSession.provider_id,
              data: {
                init: result,
              },
            });

            if (payPromiseRef.current) {
              payPromiseRef.current?.resolve(true);
              payPromiseRef.current = null;
            } else {
              handleComplete();
            }
          }
        })();
      },
    );

    // In der dev-Umgebung wird der Effect immer 2 mal aufgerufen, mit useRef verhindern wir, dass amazon.Pay 2 mal ausgeführt wird
    // idInitialized.current = id;
  }, [checkoutLoaded /*, id*/, data.merchantId, handleComplete, paymentSession.provider_id, updatePaymentSession]);

  useEffect(() => {
    // Hack um zu verhindern, dass mehrere iFrames von Nexi angezeigt werden
    const parentElement = document.querySelector("#nexi-payengine-container-div");
    if (!parentElement) {
      return;
    }

    // MutationObserver initialisieren
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        // Wenn es eine childList-Mutation ist
        if (mutation.type === "childList") {
          // iFrames von Nexi finden
          const iframes = parentElement.getElementsByTagName("iframe");

          if (iframes && iframes.length > 1) {
            for (let i = iframes.length - 1; i > 0; i--) {
              //alle iframes außer dem ersten entfernen
              parentElement.removeChild(iframes[i]);
            }
          }
        }
      }
    });

    // Mutation Observer starten
    observer.observe(parentElement, { childList: true });
  }, []);

  return (
    <>
      <Script src={widgetSrc} onReady={() => setCheckoutLoaded(true)} />
      {/* Da es kein Nexi-Unmount script gibt, sorgen wir durch geänderten key dafür, dass React das alte DOM-Element entfernt und ein neues frisches anlegt, falls sich paymentId ändert */}
      <div id={"nexi-payengine-container-div"}></div>
    </>
  );
};
