import { ErrorMessage } from "@hookform/error-message";
import clsx from "clsx";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { get } from "react-hook-form";

import NativeSelect, { NativeSelectProps } from "@/modules/common/components/native-select";

type InputProps = NativeSelectProps & {
  errors?: Record<string, unknown>;
  touched?: Record<string, unknown>;
  name: string;
};

const salutations = [
  { label: "Herr", value: "m" },
  { label: "Frau", value: "f" },
];

const SalutationSelect = forwardRef<HTMLSelectElement, InputProps>(({ errors, name, className, placeholder = "Anrede", ...props }, ref) => {
  const innerRef = useRef<HTMLSelectElement>(null);

  useImperativeHandle<HTMLSelectElement | null, HTMLSelectElement | null>(ref, () => innerRef.current);

  const hasError = get(errors, name);

  return (
    <div>
      <NativeSelect
        name={name}
        ref={innerRef}
        placeholder={placeholder}
        className={clsx(
          "border-2 focus:outline-none font-semibold border-gray-500 bg-gray-100 text-gray-700",
          {
            "border-red-500 bg-gray-100 text-red-500 placeholder-red-500": hasError,
          },
          className,
        )}
        {...props}
      >
        {salutations.map(({ value, label }, index) => (
          <option key={index} value={value} className="text-gray-700">
            {label}
          </option>
        ))}
      </NativeSelect>
      {hasError && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => {
            return (
              <div className="text-red-500 font-semibold">
                <span>{message}</span>
              </div>
            );
          }}
        />
      )}
    </div>
  );
});

SalutationSelect.displayName = "SalutationSelect";

export default SalutationSelect;
