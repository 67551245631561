import { Address, Cart } from "@medusajs/medusa";
import { Cart as CartType } from "@medusajs/medusa/dist/models/cart";
import { useCart, useRegions } from "medusa-react";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm, UseFormSetValue } from "react-hook-form";
import Skeleton from "react-loading-skeleton";

import { RequiredLabel } from "@/components/Form/required-label";
import { AddressInterface } from "@/components/pages/account/AddAddress";
import medusaRequest from "@/data/medusa-fetch";
import { shippingAddressPayload, useStore } from "@/lib/contexts/store-context";
import { handleError } from "@/lib/util/handle-error";
import { addressPayload } from "@/modules/checkout/components/Address/AddressModal";
import { BillingAddress } from "@/modules/checkout/components/Address/BillingAddress/ViewBillingAddress";
import { regionFromAddress } from "@/modules/checkout/payment";
import Button from "@/modules/common/components/button";

import { AddressForm, validateFormData } from "../AddressForm";

const BillingAddressSkeleton = () => {
  return (
    <>
      <Skeleton className="w-2/3" />
      <Skeleton className="w-1/3" />
      <Skeleton className="w-1/3" />
      <Skeleton className="w-1/3" />
    </>
  );
};

export const GuestViewBillingAddress = ({ cart, isLoading }: { cart: Cart; isLoading: boolean }) => {
  const isBillingHomeDelivery = cart?.billing_address?.metadata?.delivery_type === "home";

  const [isMigrating, setMigrating] = useState<boolean>(false);
  const { updateCart, setCart } = useCart();
  const { regions } = useRegions();
  const { migrateCart } = useStore();
  const [sameAsShipping, setSameAsShipping] = useState<boolean>(isBillingHomeDelivery);

  useEffect(() => {
    if (cart?.id && !cart.billing_address_id && cart?.shipping_address?.metadata?.delivery_type === "home") {
      updateBillingAddress(cart?.shipping_address as AddressInterface, true);
    }
  }, [cart?.id]);

  const updateBillingAddress = async (data: AddressInterface, isSameAsShipping: boolean) => {
    const newBillingAddress = isSameAsShipping ? shippingAddressPayload(data as Address) : addressPayload(data);
    const billingRegionId = regionFromAddress(newBillingAddress.country_code!, regions)?.id;

    if (cart.region_id !== billingRegionId) {
      // create new cart and migrate cart data
      setMigrating(true);
      await migrateCart(
        cart,
        {
          region_id: billingRegionId,
        },
        {
          billing_address: newBillingAddress,
        },
      );

      setSameAsShipping(isSameAsShipping);
      setMigrating(false);
    } else {
      updateCart.mutate(
        { billing_address: newBillingAddress },
        {
          onSuccess: async ({ cart }) => {
            if (cart.metadata?.shippingCostUpdateRequired) {
              const cartValidation = await medusaRequest("POST", `/carts/${cart.id}/validate-shipping`);
              if (cartValidation.ok) {
                setCart(cartValidation.body.cart);
              } else {
                handleError(new Error(`Error validating shipping for cart ${cart.id}`));
              }
            } else {
              setCart(cart);
            }

            setSameAsShipping(isSameAsShipping);
            setMigrating(false);
          },
        },
      );
    }
  };

  if (isMigrating || isLoading) {
    return <BillingAddressSkeleton />;
  }

  return (
    <>
      {isBillingHomeDelivery && (
        <div className="flex justify-between">
          <h2 className={`font-extrabold ${!isBillingHomeDelivery ? "mb-7" : null}`}>Rechnungsadresse</h2>

          <button onClick={() => setSameAsShipping(!sameAsShipping)} className="underline font-extrabold text-xs">
            Bearbeiten
          </button>
        </div>
      )}
      {sameAsShipping && isBillingHomeDelivery ? (
        <BillingAddress cart={cart as CartType} isLoading={isLoading} />
      ) : (
        <>
          {!isBillingHomeDelivery && (
            <div className="mb-7">
              <h3 className="text-xl font-black text-center mb-2">Rechnungsadresse hinzufügen</h3>
              <p className="text-base font-bold">
                Da Sie eine Packstation oder Postfiliale als Lieferadresse ausgewählt haben, geben Sie hier bitte eine gültige Rechnungsadresse ein.
              </p>
            </div>
          )}

          <RegisterBillingAddress cart={cart} updateCart={updateBillingAddress} isBillingAddress={true} />
        </>
      )}
    </>
  );
};

export const RegisterBillingAddress = ({
  updateCart,
  cart,
  isBillingAddress,
}: {
  updateCart: (data: AddressInterface, hasMetadata: boolean) => void;
  cart: Cart;
  isBillingAddress?: boolean;
}) => {
  const form = useForm<AddressInterface>({ defaultValues: { delivery_type: "home" } });
  const { handleSubmit, setValue } = form;

  const onSubmit = handleSubmit(async (data: AddressInterface) => {
    if (!(await validateFormData(form, data))) {
      return;
    }
    updateCart(data, false);
  });

  useEffect(() => {
    if (cart?.billing_address_id) {
      handleAddressValues(setValue, cart?.billing_address as Address, isBillingAddress);
    }
  }, [cart?.id]);

  return (
    <>
      <FormProvider {...form}>
        <AddressForm isBillingAddress />
      </FormProvider>

      <Button className="p-5 font-light text-lg text-center uppercase w-full" variant="dark" onClick={onSubmit}>
        Speichern
      </Button>
      <RequiredLabel />
    </>
  );
};

export const handleAddressValues = (setValue: UseFormSetValue<AddressInterface>, address: Address, isBillingAddress?: boolean) => {
  setValue("id", address?.id as string);
  setValue("first_name", address.first_name as string);
  setValue("last_name", address.last_name as string);
  setValue("company", address.company as string);
  setValue("salutation", address?.metadata?.salutation as string);
  const birthdayMatch = (address?.metadata?.birthday as string | undefined)?.match(/(\d{4})-(\d{2})-(\d{2})/);
  if (birthdayMatch) {
    setValue("birthday", `${birthdayMatch[3]}.${birthdayMatch[2]}.${birthdayMatch[1]}`);
  } else {
    setValue("birthday", "");
  }
  setValue("address_1", address.address_1 as string);
  setValue("address_2", address.address_2 as string);
  setValue("city", address.city as string);
  setValue("country_code", address.country_code as string);
  setValue("province", address.province as string);
  setValue("postal_code", address.postal_code as string);
  setValue("phone", address.phone as string);
  setValue("invoice_address", !!address?.metadata?.invoice_address);

  if (!isBillingAddress) {
    setValue("post_number", address?.metadata?.post_number as string);
    setValue("paket_station_number", address?.metadata?.paket_station_number as string);
    setValue("delivery_type", address?.metadata?.delivery_type as string);
  }
};
