"use client";

import { Address, Cart as CartType, Region } from "@medusajs/medusa";
import * as Sentry from "@sentry/nextjs";
import { useCart, useCartShippingOptions, useRegions } from "medusa-react";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { AddressInterface } from "@/components/pages/account/AddAddress";
import { CartDiscount } from "@/components/pages/checkout/CartDiscount";
import { PaymentListSkeleton } from "@/components/Skeleton/Checkout/PaymentListSkeleton";
import medusaRequest from "@/data/medusa-fetch";
import { useAccount } from "@/lib/contexts/account-context";
import { useCheckout } from "@/lib/contexts/checkout-context";
import { useLoadingRouter } from "@/lib/contexts/loading-context";
import { shippingAddressPayload, useStore } from "@/lib/contexts/store-context";
import { handleError } from "@/lib/util/handle-error";
import { AddressModal, addressPayload } from "@/modules/checkout/components/Address/AddressModal";
import { GuestViewBillingAddress, RegisterBillingAddress } from "@/modules/checkout/components/Address/BillingAddress/GuestViewBillingAddress";
import { AddressTitle, BillingAddress } from "@/modules/checkout/components/Address/BillingAddress/ViewBillingAddress";
import { Payments, SetSubmitCallback, SubmitCallback } from "@/modules/checkout/components/Payments";
import Button from "@/modules/common/components/button";

const PaymentSection = ({
  cart,
  setSubmitCallback,
  isLoading,
  handleComplete,
}: {
  cart: CartType;
  setSubmitCallback: SetSubmitCallback;
  handleComplete: () => void;
  isLoading: boolean;
}) => {
  if (isLoading || !cart?.payment_sessions === undefined) {
    return <PaymentListSkeleton />;
  }

  if ((cart.payment_sessions?.length || 0) <= 0) {
    return (
      <>
        <h1 className="font-thin uppercase text-2xl text-center mb-5">Jetzt zahlen</h1>
        <p className="text-center">Keine Zahlungsmethoden gefunden.</p>
      </>
    );
  }

  return <Payments cart={cart} setSubmitCallback={setSubmitCallback} handleComplete={handleComplete} />;
};

export const regionFromAddress = (countryCode: string, regions: Region[] | undefined): Region | undefined =>
  regions?.find((r) => undefined !== r.countries.find((c) => c.iso_2 === countryCode));

export default function Payment() {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isCartMigrating, setCartMigrating] = useState<boolean>(false);
  const [isPaymentLoading, setPaymentLoading] = useState<boolean>(true);
  const [isEdit, setEdit] = useState<boolean>(false);
  const router = useLoadingRouter();
  const { initPayment } = useCheckout();
  const { cart, setCart, updateCart, addShippingMethod } = useCart();
  const { migrateCart } = useStore();
  const { shipping_options } = useCartShippingOptions(cart?.id ?? "", { enabled: !!cart?.id });
  const { customer } = useAccount();
  const { regions } = useRegions();
  const submitCallback = useRef<SubmitCallback>(() => Promise.resolve(true));
  const [submitting, setSubmitting] = useState(false);

  const handleComplete = useCallback(async () => {
    setSubmitting(true);
    try {
      const res = await submitCallback.current();
      if (res) {
        router.push("/checkout/overview");
      }
    } catch (error) {
      Sentry.captureException(error);
      console.error("submitCallback failed", error);
      // TODO: Fehler im Frontend darstellen
    } finally {
      setSubmitting(false);
    }
  }, [submitCallback, router, setSubmitting]);
  const setSubmitCallback: SetSubmitCallback = useCallback((cb: SubmitCallback | null) => (submitCallback.current = cb ?? (() => Promise.resolve(true))), []);

  const setBillingAddress = useCallback(
    async (address: AddressInterface, hasMetadata: boolean) => {
      const newBillingAddress = hasMetadata ? shippingAddressPayload(address as Address) : addressPayload(address);
      const billingRegionId = regionFromAddress(newBillingAddress.country_code!, regions)?.id;

      if (cart && cart.region_id !== billingRegionId) {
        // create new cart and migrate cart data
        setCartMigrating(true);
        await migrateCart(
          cart,
          {
            region_id: billingRegionId,
          },
          {
            billing_address: newBillingAddress,
          },
        );
        setCartMigrating(false);
        setLoading(false);
      } else {
        updateCart.mutate(
          { billing_address: newBillingAddress },
          {
            onSuccess: async ({ cart }) => {
              if (cart.metadata?.shippingCostUpdateRequired) {
                const cartValidation = await medusaRequest("POST", `/carts/${cart.id}/validate-shipping`);
                if (cartValidation.ok) {
                  setCart(cartValidation.body.cart);
                } else {
                  handleError(new Error(`Error validating shipping for cart ${cart.id}`));
                }
              } else {
                setCart(cart);
              }

              setLoading(false);
            },
          },
        );
      }
    },
    [setCart, updateCart],
  );

  useEffect(() => {
    if (!cart?.id) {
      return;
    }
    setLoading(isCartMigrating);

    if ((!cart?.billing_address?.first_name || !cart?.billing_address?.last_name) && customer) {
      const addresses = customer?.shipping_addresses;

      if (addresses.length >= 1) {
        const address = cart?.shipping_address ?? addresses?.at(0);

        if (address?.metadata?.delivery_type === "home") {
          setLoading(true);
          setBillingAddress(address as AddressInterface, true);
        }
      }
    }
  }, [cart?.id]);

  useEffect(() => {
    if (!cart?.id || cart.payment_sessions?.length) {
      setPaymentLoading(false);
      return;
    }
    initPayment().then(() => {
      setPaymentLoading(false);
    });
  }, [cart?.id, cart?.payment_sessions?.length, initPayment, setPaymentLoading]);

  const needsContinue = true;

  return (
    <>
      <h1 className="text-center text-4xl uppercase">Zahlung</h1>
      <hr className="mt-7 mb-14 h-0.5 border-t-0 bg-black opacity-100" />

      {customer && cart?.billing_address?.metadata?.delivery_type === "home" && (
        <div className="flex justify-between pb-6 pt-8">
          <AddressTitle isEdit={isEdit} setEdit={setEdit} cart={cart as CartType} title="Rechnungsadresse" />
          <AddressModal selectBillingAddress />
        </div>
      )}

      <div className="mb-20">
        {customer ? (
          <CustomerPaymentAddress cart={cart as CartType} isLoading={isLoading || !cart?.id} updateCart={setBillingAddress} />
        ) : (
          <GuestViewBillingAddress cart={cart as CartType} isLoading={isLoading || !cart?.id} />
        )}
      </div>

      {/*<ShippingOptions />*/}

      <PaymentSection
        cart={cart as CartType}
        setSubmitCallback={setSubmitCallback}
        handleComplete={handleComplete}
        isLoading={isPaymentLoading || isLoading || !cart?.id}
      />

      <CartDiscount cart={cart as CartType} isLoading={isPaymentLoading || isLoading || !cart?.id} />

      {needsContinue && (
        <Button
          className="p-5 mt-10 w-full font-light text-lg text-center uppercase"
          disabled={!cart?.billing_address || !cart?.payment_session || submitting || isPaymentLoading || isLoading || !cart?.id}
          variant="active"
          onClick={handleComplete}
        >
          Weiter
        </Button>
      )}
    </>
  );
}

const CustomerPaymentAddress = ({
  cart,
  isLoading,
  updateCart,
}: {
  cart: CartType;
  isLoading: boolean;
  updateCart: (data: AddressInterface, hasMetadata: boolean) => void;
}) => {
  if (
    (cart?.shipping_address?.metadata?.delivery_type === "home" && cart?.shipping_address_id === cart?.billing_address_id) ||
    cart?.billing_address?.metadata?.delivery_type === "home"
  ) {
    return <BillingAddress cart={cart} isLoading={isLoading} />;
  }

  return (
    <>
      <div className="mb-7">
        <h1 className="text-xl font-black text-center mb-2">Rechnungsadresse hinzufügen</h1>
        <p className="text-base font-bold">
          Da Sie eine Packstation oder Postfiliale als Lieferadresse ausgewählt haben, geben Sie hier bitte eine gültige Rechnungsadresse ein.
        </p>
      </div>

      <RegisterBillingAddress updateCart={updateCart} cart={cart} />
    </>
  );
};
