import { isEqual, omit } from "lodash";
import React, { useEffect } from "react";

import { RadioInput } from "@/components/Form/Input/RadioInput";
import { Flyout } from "@/components/Modal/Flyout";
import { AddressInterface } from "@/components/pages/account/AddAddress";
import { useAccount } from "@/lib/contexts/account-context";
import { useCheckout } from "@/lib/contexts/checkout-context";
import { CHECKOUT_ADDRESS, useSideModal } from "@/lib/contexts/sidemodal-context";
import { Addresses, AddressesInterface, BaseActionInterface } from "@/modules/checkout/components/Address/Modal/base-action";
import Button from "@/modules/common/components/button";

interface SelectActionInterface extends BaseActionInterface {
  onSelect: (address: AddressInterface) => void;
}

export const SelectAddressAction = (props: SelectActionInterface & AddressesInterface) => {
  const {
    modal: { isShow, name },
  } = useSideModal();
  const { getValues } = useCheckout();
  const { customer } = useAccount();
  const isOpen = name === CHECKOUT_ADDRESS && isShow;

  useEffect(() => {
    if (!isOpen || props.selectedAddress) {
      return;
    }

    const currentShippingAddress = getValues("shipping_address");
    props.setSelectedAddress(
      customer?.shipping_addresses?.find((address) =>
        isEqual(omit(address, ["id", "created_at", "updated_at", "country", "deleted_at", "metadata", "customer_id"]), currentShippingAddress),
      ) as AddressInterface | undefined,
    );
  }, [isOpen, props.selectedAddress]);

  const submitButton = (
    <Button
      className="py-2 px-4 bg-pink-600 text-white rounded hover:bg-pink-700 mr-2 uppercase w-full"
      onClick={() => {
        if (props.checked === 2) {
          props.setNewAddress(true);
          return;
        }

        props.onSelect(props.selectedAddress as AddressInterface);
      }}
    >
      Auswählen
    </Button>
  );

  return (
    <Flyout open={isOpen} right={true} onClose={props.onClose} button={submitButton}>
      <div className="text-lg pb-7">
        <p className="font-extrabold text-center text-lg mb-3">Adresse auswählen</p>
        <Addresses
          checked={props.checked}
          setChecked={props.setChecked}
          setSelectedAddress={props.setSelectedAddress}
          setNewAddress={props.setNewAddress}
          setEditAddress={props.setEditAddress}
          selectedAddress={props.selectedAddress}
          modalForm={props.modalForm}
        />
        <hr className="mt-7 mb-7 h-0.5 border-t-0 bg-black opacity-100" />

        <RadioInput name="radio" checked={props.checked === 2} onChange={() => props.setChecked(2)} labelClassName={"items-center"}>
          <span className="font-bold text-lg ml-4">Neue Adresse hinzufügen</span>
        </RadioInput>
      </div>
    </Flyout>
  );
};
