import React from "react";

import { AddressInterface } from "@/components/pages/account/AddAddress";
import { BaseActionInterface } from "@/modules/checkout/components/Address/Modal/base-action";
import { CreateAddress } from "@/modules/checkout/components/Address/Modal/create-action";
import { UpdateAddressAction } from "@/modules/checkout/components/Address/Modal/update-action";

interface AddressFlyoutInterface extends BaseActionInterface {
  onSelect: (address: AddressInterface) => void;
  isCreate: boolean;
  isEdit: boolean;
  children: React.ReactNode;
}

export const AddressModalFlyout = (props: AddressFlyoutInterface) => {
  if (props.isCreate) {
    return <CreateAddress {...props} />;
  }

  if (props.isEdit) {
    return <UpdateAddressAction {...props} />;
  }

  return <>{props.children}</>;
};
