import { PaymentSession } from "@medusajs/medusa";
import React from "react";

import { Collapse } from "@/components/Form/Button/Collapse";
import { RadioInput } from "@/components/Form/Input/RadioInput";

import { SetSubmitCallback } from "./index";
import { CommdooCheckout } from "./payment-commdoo";
import { KlarnaWidget } from "./payment-klarna";
import { NexiPayengineWidget } from "./payment-nexi-payengine";

type PaymentContainerProps = {
  paymentSession: PaymentSession;
  selected: boolean;
  setSelected: () => void;
  disabled?: boolean;
  setSubmitCallback: SetSubmitCallback;
  handleComplete: () => void;
};

export const PaymentInfoMap: Record<string, { title: string; description: string; icon?: React.ReactNode }> = {
  paypal: {
    title: "PayPal",
    // icon: <PayPalIcon className="w-10" />,
    description:
      "Überprüfen oder bearbeiten Sie Ihre Rechnungsadresse. Falls Sie später bezahlen möchten, klicken Sie unten auf den Button ‚Mit PayPal zahlen‘ und wählen Sie die Option ‚Bezahlung nach 30 Tagen‘.",
  },
  "klarna-payments": {
    title: "Klarna",
    description: "Klarna Rechnung - Bezahle in bis zu 30 Tagen. Profitiere vom Käuferschutz. Verwalte deine Zahlungen in der Klarna App.",
    // icon: (
    //   <Image
    //     className={"justify-self-end"}
    //     src={"https://x.klarnacdn.net/payment-method/assets/badges/generic/klarna.svg"}
    //     alt={"Klarna Payments"}
    //     width={45}
    //     height={19}
    //   />
    // ),
  },
  manual: {
    title: "Test payment",
    description: "Test payment using medusa-payment-manual",
  },
  amazonpay: {
    title: "Amazon Pay",
    description: "Mit Amazon bezahlen. Versand durch Channel21",
  },
  commdoo_santander_invoice: {
    title: "Zinia Rechnung",
    description: "Bezahlen Sie bequem auf Rechnung mit Zinia. Die Rechnung wird Ihnen per E-Mail zugesendet.",
  },
  commdoo_santander_instalment: {
    title: "Zinia Ratenzahlung",
    description: "Bezahlen Sie bequem in Raten mit Zinia. Die Ratenzahlung wird Ihnen per E-Mail zugesendet.",
  },
  nexi_payengine_creditcard: {
    title: "Kreditkarte",
    description: "Jetzt mit Kredikarte bezahlen.",
  },
};

const PaymentContainer: React.FC<PaymentContainerProps> = ({ paymentSession, selected, setSelected, setSubmitCallback, handleComplete }) => {
  const payment = PaymentInfoMap[paymentSession.provider_id];
  if (!payment) {
    console.error("Unsupported Payment-Provider: ", paymentSession.provider_id);
    return null;
  }

  if (paymentSession.provider_id === "klarna-payments" && selected) {
    return (
      <PaymentCollapse name={payment.title} icon={payment?.icon ?? <></>} enabled={selected} onClick={setSelected}>
        <KlarnaWidget paymentSession={paymentSession} setSubmitCallback={setSubmitCallback} />
      </PaymentCollapse>
    );
  }
  if (paymentSession.provider_id.startsWith("commdoo") && selected) {
    return (
      <PaymentCollapse name={payment.title} icon={payment?.icon ?? <></>} enabled={selected} onClick={setSelected}>
        <CommdooCheckout paymentSession={paymentSession} setSubmitCallback={setSubmitCallback} />
      </PaymentCollapse>
    );
  }
  if (paymentSession.provider_id.startsWith("nexi_payengine") && selected) {
    return (
      <PaymentCollapse name={payment.title} icon={payment?.icon ?? <></>} enabled={selected} onClick={setSelected}>
        <NexiPayengineWidget paymentSession={paymentSession} setSubmitCallback={setSubmitCallback} handleComplete={handleComplete} />
      </PaymentCollapse>
    );
  }

  if (selected) {
    // no special handling on submit
    setSubmitCallback(() => Promise.resolve(true));
  }

  return (
    <PaymentCollapse name={payment.title} icon={payment?.icon ?? <></>} enabled={selected} onClick={setSelected}>
      <div className="p-3">
        <span className="text-black text-base font-semibold">{payment.description}</span>
      </div>
    </PaymentCollapse>
  );
};

const PaymentCollapse = ({
  name,
  icon,
  enabled,
  onClick,
  children,
}: {
  name: string;
  icon: React.ReactNode;
  enabled?: boolean;
  onClick: () => void;
  children: React.ReactNode;
}) => {
  return (
    <div className="mb-3">
      <Collapse
        onClick={onClick}
        id={`${name}Payment`}
        customStyle={"bg-[#f5f5f5] hover:bg-gray-100 border border-black"}
        customContentStyle={"bg-white border border-black"}
        enabled={enabled}
        disableClickAction
        title={
          <div className={"grid grid-cols-2"}>
            <RadioInput
              label={name}
              name="payment"
              className="font-extrabold cursor-pointer justify-self-stretch"
              checked={enabled}
              onChange={() => onClick()}
            />
            {icon}
          </div>
        }
      >
        {children}
      </Collapse>
    </div>
  );
};

export default PaymentContainer;
