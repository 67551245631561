import { Cart } from "@medusajs/medusa";
import { useCart, useUpdateCart } from "medusa-react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { Collapse, RenderArrow } from "@/components/Form/Button/Collapse";
import Button from "@/modules/common/components/button";
import Input from "@/modules/common/components/input";

type DiscountFormValues = {
  discount_code: string;
};

export const CartDiscount = ({ cart, isLoading }: { cart: Cart; isLoading: boolean }) => {
  const { id } = cart;
  const { mutate, isLoading: isCartLoading } = useUpdateCart(id);
  const [select, setSelect] = useState<boolean>(false);
  const { setCart } = useCart();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm<DiscountFormValues>({
    mode: "onSubmit",
  });

  const onApply = (data: DiscountFormValues) => {
    mutate(
      {
        discounts: [{ code: data.discount_code }],
      },
      {
        onSuccess: ({ cart }) => {
          setCart(cart);
          setSelect(false);
          reset();
        },
        onError: () => {
          setError(
            "discount_code",
            {
              message: "Dieser Gutscheincode konnte nicht gefunden werden",
            },
            {
              shouldFocus: true,
            },
          );
        },
      },
    );
  };
  return (
    <div className="mt-10">
      <p className="font-bold">Haben Sie einen Gutscheincode?</p>
      <div className="w-full my-4">
        <Collapse
          onClick={() => setSelect(!select)}
          id={"checkout-discount"}
          customStyle={"bg-gray-100 hover:bg-gray-200"}
          customContentStyle={"bg-white border-2 border-gray-100 px-3"}
          enabled={select}
          title={
            <>
              <span>Gutscheincode eingeben</span>
              <RenderArrow up={select} />
            </>
          }
        >
          <div className="my-4">
            <div className="text-small-regular">
              <form onSubmit={handleSubmit(onApply)} className="w-full">
                <Input
                  className="w-full"
                  label="Code"
                  {...register("discount_code", {
                    required: "Code is required",
                  })}
                  errors={errors}
                  disabled={isLoading || isCartLoading}
                />
                <Button variant="active" className="p-5 w-full font-light text-lg text-center uppercase mt-3" disabled={isLoading || isCartLoading}>
                  Gutschein einlösen
                </Button>
              </form>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};
