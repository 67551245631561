import { ErrorMessage } from "@hookform/error-message";
import clsx from "clsx";
import React, { useImperativeHandle } from "react";
import { get } from "react-hook-form";

type InputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, "placeholder"> & {
  label: string;
  errors?: Record<string, unknown>;
  touched?: Record<string, unknown>;
  name: string;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ type, name, label, errors, className, ...props }, ref) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => inputRef.current!);

  const hasError = get(errors, name);

  return (
    <div>
      <div className="relative z-0 w-full text-base-regular">
        <input
          type={type}
          name={name}
          placeholder={label}
          className={clsx(
            "border-2 focus:outline-none py-2 px-4 font-semibold border-gray-500 bg-gray-100 text-gray-700 placeholder-ch21-darkgray",
            {
              "border-red-500 bg-gray-100 text-red-500 placeholder-red-500": hasError,
            },
            className,
          )}
          {...props}
          ref={inputRef}
        />
      </div>
      {hasError && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => {
            return (
              <div className="text-red-500 font-semibold">
                <span>{message}</span>
              </div>
            );
          }}
        />
      )}
    </div>
  );
});

Input.displayName = "Input";

export default Input;
