import { ErrorMessage } from "@hookform/error-message";
import type { ComboBoxProps } from "@react-types/combobox";
import clsx from "clsx";
import React, { useEffect, useImperativeHandle, useRef } from "react";
import { useComboBox } from "react-aria";
import { get, useFormContext, useWatch } from "react-hook-form";
import { useComboBoxState } from "react-stately";

import ListBox from "./list-box";
import Popover from "./popover";

type InputProps<T> = ComboBoxProps<T> &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, "placeholder" | "children"> & {
    label: string;
    errors?: Record<string, unknown>;
    touched?: Record<string, unknown>;
    name: string;
  };

// Quelle: https://codesandbox.io/p/sandbox/hardcore-moon-xzc4r?file=%2Fsrc%2FSearchAutocomplete.tsx%3A44%2C59
// https://react-spectrum.adobe.com/react-aria/useComboBox.html
// https://react-spectrum.adobe.com/react-aria/forms.html#react-hook-form

function InputAutoSuggest<T extends object>(
  { autoComplete, type, name, label, errors, touched: _touched, required: _required, className, ...props }: InputProps<T>,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const inputValue = useWatch({ name });
  const { getValues, setValue } = useFormContext();
  const state = useComboBoxState<T>({
    ...props,
    allowsCustomValue: props.allowsCustomValue ?? true,
    onInputChange: (value) => {
      setValue(name, value);
    },
    inputValue: inputValue ?? getValues(name),
  });

  const inputRef = React.useRef<HTMLInputElement>(null);
  const listBoxRef = React.useRef(null);
  const popoverRef = React.useRef(null);
  useImperativeHandle(ref, () => inputRef.current!);

  const { inputProps, listBoxProps } = useComboBox(
    {
      ...props,
      "aria-label": label,
      inputRef,
      listBoxRef,
      popoverRef,
    },
    state,
  );

  // Begin Fix: Open Lazy Suggest
  const prevListLength = useRef(Array.isArray(props.items) ? props.items.length : 0);
  useEffect(() => {
    if (!Array.isArray(props.items)) {
      return;
    }
    if (state.isFocused && !state.isOpen && props.items.length > 0 && prevListLength.current === 0) {
      state.open();
    }
    prevListLength.current = props.items.length;
  }, [state.isOpen, state.isFocused, props.items, state]);
  // End Fix

  const hasError = get(errors, name);

  const disableAutoComplete = Boolean(state.collection.size && state.isFocused);

  return (
    <div>
      <div className="relative z-0 w-full text-base-regular">
        <input
          type={type}
          name={name}
          className={clsx(
            "border-2 focus:outline-none py-2 px-4 font-semibold border-gray-500 bg-gray-100 text-gray-700 placeholder-ch21-darkgray",
            {
              "border-red-500 bg-gray-100 text-red-500 placeholder-red-500": hasError,
            },
            className,
          )}
          {...inputProps}
          aria-label={disableAutoComplete ? inputProps.autoComplete : label}
          placeholder={disableAutoComplete ? inputProps.placeholder : label}
          autoComplete={disableAutoComplete ? inputProps.autoComplete : autoComplete}
          ref={inputRef}
        />
        {state.isOpen && (
          <Popover popoverRef={popoverRef} triggerRef={inputRef} state={state} isNonModal placement="bottom start">
            <ListBox {...listBoxProps} listBoxRef={listBoxRef} state={state} />
          </Popover>
        )}
      </div>
      {hasError && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => {
            return (
              <div className="text-red-500 font-semibold">
                <span>{message}</span>
              </div>
            );
          }}
        />
      )}
    </div>
  );
}

export default React.forwardRef(InputAutoSuggest) as <T>(
  props: InputProps<T> & { ref?: React.ForwardedRef<HTMLInputElement> },
) => ReturnType<typeof InputAutoSuggest>;
