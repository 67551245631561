import React from "react";
import Skeleton from "react-loading-skeleton";

export const AddressTitleSkeleton = () => {
  return (
    <>
      <div>
        <Skeleton count={1} height="1rem" width={"10rem"} style={{ marginBottom: ".25rem" }} />
      </div>
      <div>
        <Skeleton count={1} height="1rem" width={"10rem"} style={{ marginBottom: ".25rem" }} />
      </div>
    </>
  );
};

export const AddressSkeleton = () => {
  return (
    <>
      <Skeleton count={3} height="1.25rem" width={"50%"} style={{ marginBottom: ".5rem" }} />
      <Skeleton count={1} height="1.25rem" width={"75%"} style={{ marginTop: "1.25rem" }} />
    </>
  );
};
