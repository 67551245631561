import { PaymentSession, Region } from "@medusajs/medusa";
import clsx from "clsx";
import InnerHTML from "dangerously-set-html-content";
import { useCart, useUpdatePaymentSession } from "medusa-react";
import React, { useCallback, useEffect, useState } from "react";

import { DropdownButton } from "@/components/Form/Button/DropdownButton";
import { CheckboxInput } from "@/components/Form/Input/CheckboxInput";
import { useCheckout } from "@/lib/contexts/checkout-context";
import { useProductPriceFormat } from "@/lib/hooks/use-product-price";
import Button from "@/modules/common/components/button";

import { SetSubmitCallback } from "./index";

interface Rate {
  anzahlDerRaten: number;
  effektiverZinssatz: number;
  gebuehrErsteRate: number | null;
  gebuehrJedeRate: number | null;
  gesamtbetrag: number;
  kaufbetrag: number;
  letzteRate: number;
  monatlicheRate: number;
  nominalerZinssatz: number;
  zinsSumme: number;
}

interface PaymentSessionData extends Record<string, unknown> {
  optInMarketing?: boolean;
  optInPrivacyPolicy?: boolean;
  selectedRate?: number;
  preInit: {
    optInMarketing?: string;
    optInPrivacyPolicy?: string;
    optIn?: string;
    htmlSnippetBodyElementIFrameContent?: string;
    htmlSnippetHeadScriptContent?: string;
  };
  santanderPaymentSpecifics?: Array<Rate>;
}

const SelectRate = ({
  santanderPaymentSpecifics,
  selectedRate,
  setSelectedRate,
}: {
  santanderPaymentSpecifics: Array<Rate>;
  selectedRate?: number;
  setSelectedRate: (rate: number) => void;
}) => {
  const { cart } = useCart();
  const price = useProductPriceFormat({ region: cart?.region as Region });

  const currentRate = santanderPaymentSpecifics.find((rate) => rate.anzahlDerRaten === selectedRate);
  const buttonTitle = selectedRate && currentRate ? `${selectedRate} Raten x ${price.output(currentRate?.monatlicheRate * 100)}` : "Ratenplan auswählen";

  return (
    <div className="p-5 pt-4">
      {/* TODO: Besseres Dropdown */}
      <DropdownButton title={buttonTitle} closeOnClick defaultOpen={selectedRate === undefined} colorClasses={"bg-ch21-gray"} ulPadding={""}>
        {[...santanderPaymentSpecifics]
          .sort((a, b) => a.anzahlDerRaten - b.anzahlDerRaten)
          .map((rate) => (
            <li
              key={rate.anzahlDerRaten}
              onClick={() => setSelectedRate(rate.anzahlDerRaten)}
              className={clsx("px-4 py-1 ", {
                "bg-ch21-darkergray group-[:hover]:bg-transparent group-[:hover]:hover:bg-ch21-darkergray-rates": rate.anzahlDerRaten === selectedRate,
                "hover:bg-ch21-darkergray-rates": rate.anzahlDerRaten !== selectedRate,
              })}
            >
              {rate.anzahlDerRaten} Raten x {price.output(rate.monatlicheRate * 100)}
            </li>
          ))}
      </DropdownButton>
      {currentRate && (
        <div className="mt-2">
          <h2 className={"font-bold"}>Ihre Ratenzahlung im Detail</h2>
          <div className={"text-sm"}>
            <p className={"flex flex-wrap w-full p-2.5"}>
              <span className={"w-[150px]"}>Ratenanzahl</span>
              <span className={"min-w-[100px] inline-block text-right"}>{currentRate.anzahlDerRaten}</span>
            </p>

            <p className={"flex flex-wrap w-full p-2.5"}>
              <span className={"w-[150px]"}>Bestellwert</span>
              <span className={"min-w-[100px] inline-block text-right"}>{price.output(currentRate.kaufbetrag * 100)}</span>
            </p>

            <p className={"flex flex-wrap w-full p-2.5 bg-ch21-gray-rates"}>
              <span className={"w-[150px]"}>Ihre monatliche Rate</span>
              <span className={"min-w-[100px] inline-block text-right"}>{price.output(currentRate.monatlicheRate * 100)}</span>
            </p>

            <p className={"flex flex-wrap w-full p-2.5"}>
              <span className={"w-[150px]"}>Letzte Rate</span>
              <span className={"min-w-[100px] inline-block text-right"}>{price.output(currentRate.letzteRate * 100)}</span>
            </p>

            {currentRate.gesamtbetrag.toFixed(2) !== currentRate.kaufbetrag.toFixed(2) && (
              <>
                {currentRate.gebuehrErsteRate ? (
                  <>
                    <span className={"w-[150px]"}>Gebühr erste Rate</span>
                    <span className={"min-w-[100px] inline-block text-right"}>{price.output(currentRate.gebuehrErsteRate * 100)}</span>
                  </>
                ) : null}

                {currentRate.gebuehrJedeRate ? (
                  <>
                    <span className={"w-[150px]"}>Gebühr je Rate</span>
                    <span className={"min-w-[100px] inline-block text-right"}>{price.output(currentRate.gebuehrJedeRate * 100)}</span>
                  </>
                ) : null}

                <span className={"w-[150px]"}>Nominaler Zinssatz</span>
                <span className={"min-w-[100px] inline-block text-right"}>{currentRate.nominalerZinssatz}</span>

                <span className={"w-[150px]"}>Effektiver Zinssatz</span>
                <span className={"min-w-[100px] inline-block text-right"}>{currentRate.effektiverZinssatz}</span>

                <span className={"w-[150px]"}>Zins-Summe</span>
                <span className={"min-w-[100px] inline-block text-right"}>{price.output(currentRate.zinsSumme * 100)}</span>

                <span className={"w-[150px]"}>Gesamtbetrag</span>
                <span className={"min-w-[100px] inline-block text-right"}>{price.output(currentRate.gesamtbetrag * 100)}</span>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export const CommdooCheckout = ({ paymentSession, setSubmitCallback }: { paymentSession: PaymentSession; setSubmitCallback: SetSubmitCallback }) => {
  const data = paymentSession.data as PaymentSessionData;
  const [checkedOptInMarketing, setCheckedOptInMarketing] = useState(data.optInMarketing || false);
  const [checkedOptInPrivacyPolicy, setCheckedOptInPrivacyPolicy] = useState(data.optInPrivacyPolicy || false);
  const [selectedRate, setSelectedRate] = useState<number | undefined>(data.selectedRate);
  const { preInit, santanderPaymentSpecifics } = data;
  const { cart, setCart } = useCart();
  const { setPaymentSession } = useCheckout();
  const updatePaymentSession = useUpdatePaymentSession(cart?.id || "");
  const [validation, setValidation] = useState(false);
  const isRatePay = paymentSession.provider_id === "commdoo_santander_instalment";

  useEffect(() => {
    setSubmitCallback(async () => {
      if (!checkedOptInPrivacyPolicy) {
        setValidation(true);
        return false;
      }
      if (isRatePay && !selectedRate) {
        setValidation(true);
        return false;
      }

      await updatePaymentSession.mutateAsync({
        provider_id: paymentSession.provider_id,
        data: {
          init: {
            optInMarketing: checkedOptInMarketing,
            optInPrivacyPolicy: checkedOptInPrivacyPolicy,
            selectedRate: selectedRate,
          },
        },
      });
      const newCart = await setPaymentSession(paymentSession.provider_id); // call updatePayment to execute Init
      if (newCart) {
        setCart(newCart);
      }

      return true;
    });
    return () => setSubmitCallback(null);
  }, [
    checkedOptInMarketing,
    checkedOptInPrivacyPolicy,
    updatePaymentSession.mutateAsync,
    isRatePay,
    paymentSession.provider_id,
    selectedRate,
    setSubmitCallback,
    updatePaymentSession,
    setPaymentSession,
    setCart,
  ]);

  const continueInit = useCallback(async () => {
    if (!checkedOptInPrivacyPolicy) {
      setValidation(true);
      return false;
    }
    await updatePaymentSession.mutateAsync({
      provider_id: paymentSession.provider_id,
      data: {
        init: {
          optInMarketing: checkedOptInMarketing,
          optInPrivacyPolicy: checkedOptInPrivacyPolicy,
        },
      },
    });
    const newCart = await setPaymentSession(paymentSession.provider_id); // call updatePayment to execute Init
    if (newCart) {
      setCart(newCart);
    }
    setValidation(false);
  }, [checkedOptInPrivacyPolicy, updatePaymentSession, paymentSession.provider_id, checkedOptInMarketing, setPaymentSession, setCart]);

  return (
    <>
      {preInit.optInPrivacyPolicy && (
        <>
          <CheckboxInput
            id={"commdoo-opt-in-privacy-policy"}
            label={<span dangerouslySetInnerHTML={{ __html: preInit.optInPrivacyPolicy }} />}
            className="font-bold text-base relative mb-2"
            checked={checkedOptInPrivacyPolicy}
            inline={true}
            onChange={() => setCheckedOptInPrivacyPolicy(!checkedOptInPrivacyPolicy)}
          />
          {validation && !checkedOptInPrivacyPolicy && (
            <p className="px-5 text-red-500">
              Sie müssen den Datenschutzbestimmungen zustimmen, um den {isRatePay ? "Ratenkauf" : "Rechnungskauf"} nutzen zu können.
            </p>
          )}
        </>
      )}
      {preInit.optInMarketing && (
        <CheckboxInput
          id={"commdoo-opt-in-marketing"}
          label={<span dangerouslySetInnerHTML={{ __html: preInit.optInMarketing }} />}
          className="font-bold text-base relative mb-2"
          checked={checkedOptInMarketing}
          inline={true}
          onChange={() => setCheckedOptInMarketing(!checkedOptInMarketing)}
        />
      )}
      {/*<p dangerouslySetInnerHTML={{ __html: preInit.optIn || "" }} />*/}

      {isRatePay && !santanderPaymentSpecifics && (
        <>
          <div className="p-5 pt-4">
            <Button onClick={continueInit} className="w-full">
              Ratenplan wählen
            </Button>
            {validation && <p className={"px-5 text-red-500"}>Es muss eine Rate ausgewählt werden um Fortfahren zu können.</p>}
          </div>
        </>
      )}
      {santanderPaymentSpecifics && (
        <>
          <SelectRate santanderPaymentSpecifics={santanderPaymentSpecifics} selectedRate={selectedRate} setSelectedRate={setSelectedRate} />
          {validation && !selectedRate && <p className="px-5 text-red-500">Sie müssen einen Ratenplan auswählen.</p>}
        </>
      )}

      {preInit.htmlSnippetHeadScriptContent && <InnerHTML html={preInit.htmlSnippetHeadScriptContent} />}
      {preInit.htmlSnippetBodyElementIFrameContent && <InnerHTML html={preInit.htmlSnippetBodyElementIFrameContent} />}
    </>
  );
};
