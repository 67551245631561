import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { Key } from "react-aria";
import { useFormContext, useWatch } from "react-hook-form";

import { AddressInterface } from "@/components/pages/account/AddAddress";
import { suggestAddresses } from "@/lib/actions/address/validate";

const wait = (ms = 1000) => new Promise((resolve) => setTimeout(resolve, ms));

type SuggestionResult = Required<Suggestion> & { id: string; value: string };
const processResults = (data: { results: Required<Suggestion>[] }, fieldname: keyof Suggestion) => {
  const records = [];
  const counts: { [key: string]: number } = {};
  const results = [];
  for (const row of data.results) {
    const item = { id: row[fieldname], ...row };
    if (fieldname == "postalCode") {
      item.id = item.postalCode + "#" + item.city;
    }
    records.push(item);

    if (typeof counts[item.id] == "undefined") counts[item.id] = 1;
    else counts[item.id]++;
  }

  for (const row of records) {
    const item: SuggestionResult = { ...row, value: row[fieldname] };
    const count = counts[item.id];
    if (count > -1) {
      if ((fieldname != "street" && count > 1) || count > 3) {
        counts[item.id] = -1;
        if (fieldname == "street") {
          item.postalCode = "";
          item.city = "";
        }
      }
      if (fieldname == "postalCode") {
        item.street = "";
      } else if (fieldname == "city") {
        item.street = "";
        item.postalCode = "";
      }

      item.value = item[fieldname];
      item.id = item[fieldname];
      if (fieldname === "street") {
        item.id += "#" + item.postalCode + "#" + item.city;
      }
      results.push(item);
    }
  }
  return results;
};

type Suggestion = { street: string; postalCode?: string; city?: string };
export const useAddressSuggestions = () => {
  const { control, setValue } = useFormContext<AddressInterface>();
  const [street, postalCode, city] = useWatch({ control, name: ["address_1", "postal_code", "city"] });

  const { data: suggestionResult } = useQuery({
    queryKey: ["address-suggestions", street, postalCode, city],
    queryFn: async ({ signal }) => {
      await wait(350);
      if (signal?.aborted) {
        return;
      }

      return suggestAddresses({ street, postalCode, city });
    },
    enabled: Boolean(street || postalCode || city),
    keepPreviousData: Boolean(street || postalCode || city),
  });

  // const suggestions = {
  //   street: useAsyncList<SuggestionResult>({
  //     async load({ signal, filterText }) {
  //       await wait(350);
  //       if (signal.aborted) {
  //         // Debounced
  //         return { items: [] };
  //       }
  //       const [postalCode, city] = getValues(["postal_code", "city"]);
  //       const data = await suggestAddresses({ street: filterText, postalCode, city });
  //
  //       return {
  //         items: processResults(data, "street"),
  //       };
  //     },
  //   }),
  //   postalCode: useAsyncList<SuggestionResult>({
  //     async load({ signal, filterText }) {
  //       await wait(350);
  //       if (signal.aborted) {
  //         // Debounced
  //         return { items: [] };
  //       }
  //       const [street, city] = getValues(["address_1", "city"]);
  //       const data = await suggestAddresses({ street, postalCode: filterText, city });
  //
  //       return {
  //         items: processResults(data, "postalCode"),
  //       };
  //     },
  //   }),
  //   city: useAsyncList<SuggestionResult>({
  //     async load({ signal, filterText }) {
  //       await wait(350);
  //       if (signal.aborted) {
  //         // Debounced
  //         return { items: [] };
  //       }
  //       const [street, postalCode] = getValues(["address_1", "postal_code"]);
  //       const data = await suggestAddresses({ street, postalCode, city: filterText });
  //
  //       return {
  //         items: processResults(data, "city"),
  //       };
  //     },
  //   }),
  // };

  const suggestions = useMemo(() => {
    if (!suggestionResult) {
      return {
        street: { items: [] },
        postalCode: { items: [] },
        city: { items: [] },
      };
    }
    return {
      street: { items: processResults(suggestionResult, "street") },
      postalCode: { items: processResults(suggestionResult, "postalCode") },
      city: { items: processResults(suggestionResult, "city") },
    };
  }, [suggestionResult]);

  return {
    suggestions,
    onSelectionChange: useCallback(
      (fieldname: keyof Suggestion, key: Key | null) => {
        if (!key) {
          return;
        }
        const item = suggestions[fieldname].items.find((row) => row.id === key);
        // const item = suggestions[fieldname].getItem(key);
        if (!item) {
          return;
        }
        setTimeout(() => {
          if (fieldname === "street") {
            // setValue("address_1", item["street"]);
            if (item["postalCode"]) {
              setValue("postal_code", item["postalCode"]);
            }
            if (item["city"]) {
              setValue("city", item["city"]);
            }
          }
          if (fieldname === "postalCode") {
            // setValue("postal_code", item["postalCode"]);
            if (item["city"]) {
              setValue("city", item["city"]);
            }
          }
          if (fieldname === "city") {
            // setValue("city", item["city"]);
          }
        }, 1);
      },
      [suggestions, setValue],
    ),
  };
};
