import { PaymentSession } from "@medusajs/medusa";
import clsx from "clsx";
import { useCart } from "medusa-react";
import Script from "next/script";
import React, { useEffect, useState } from "react";

import { medusaClient } from "@/lib/config";
import { SetSubmitCallback } from "@/modules/checkout/components/Payments/index";

declare global {
  const Klarna: {
    Payments: {
      authorize: (
        options: { auto_finalize?: boolean; payment_method_category?: string; payment_method_categories?: string[] },
        data: Record<string, unknown>,
        callback: (res: { authorization_token: string; show_form: boolean; approved: boolean; finalize_required: boolean; error?: unknown }) => void,
      ) => void;
      reauthorize: (
        options: { payment_method_category?: string },
        data: Record<string, unknown>,
        callback: (res: { authorization_token: string; show_form: boolean; approved: boolean; finalize_required: boolean; error?: unknown }) => void,
      ) => void;
      init(options: { client_token: string }): void;
      load(
        options: { container: string; preferred_payment_method?: string; payment_method_category?: string; payment_method_categories?: string[] },
        data: Record<string, unknown>,
        callback: (res: { show_form: boolean; error?: unknown }) => void,
      ): void;
    };
  };
}

export const KlarnaWidget = ({ paymentSession, setSubmitCallback }: { paymentSession: PaymentSession; setSubmitCallback: SetSubmitCallback }) => {
  const { cart, setCart } = useCart();
  const [checkoutLoaded, setCheckoutLoaded] = useState(false);
  const [id, setId] = useState(0);
  const [paymentOptionNotAvailable, setPaymentOptionNotAvailable] = useState<null | boolean>(null);

  useEffect(() => {
    setId((prevId) => prevId + 1);
  }, [paymentSession.data.clientToken]);

  useEffect(() => {
    if (checkoutLoaded && paymentOptionNotAvailable === false) {
      setSubmitCallback(async (): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
          const method = paymentSession.data.authorizationToken ? "reauthorize" : "authorize";

          Klarna.Payments[method](
            {},
            {
              customer: {
                date_of_birth: cart?.billing_address.metadata?.birthday,
              },
              billing_address: {
                city: cart?.billing_address.city,
                country: cart?.billing_address.country_code?.toUpperCase(),
                email: cart?.email,
                family_name: cart?.billing_address.last_name,
                given_name: cart?.billing_address.first_name,
                organization_name: cart?.billing_address.company,
                phone: cart?.billing_address.phone,
                postal_code: cart?.billing_address.postal_code,
                street_address: cart?.billing_address.address_1,
                street_address2: cart?.billing_address.address_2,
                title: cart?.billing_address.metadata?.salutation === "m" ? "Herr" : "Frau",
              },
              shipping_address: {
                city: cart?.shipping_address?.city,
                country: cart?.shipping_address?.country_code?.toUpperCase(),
                email: cart?.email,
                family_name: cart?.shipping_address?.last_name,
                given_name: cart?.shipping_address?.first_name,
                organization_name: cart?.shipping_address?.company,
                phone: cart?.shipping_address?.phone,
                postal_code: cart?.shipping_address?.postal_code,
                street_address: cart?.shipping_address?.address_1,
                street_address2: cart?.shipping_address?.address_2,
                title: cart?.shipping_address?.metadata?.salutation === "m" ? "Herr" : "Frau",
              },
            },
            async function (result) {
              try {
                if (result.approved) {
                  const currentCart = (
                    await medusaClient.carts.updatePaymentSession(cart?.id ?? "", "klarna-payments", {
                      data: {
                        authorization_token: result.authorization_token,
                      },
                    })
                  ).cart;

                  setCart(currentCart);
                  resolve(true);
                } else {
                  reject(new Error("Klarna authorization not approved"));
                }
              } catch (error) {
                reject(error);
              }
            },
          );
        });
      });
    }
  }, [checkoutLoaded, paymentOptionNotAvailable, setSubmitCallback]);

  useEffect(() => {
    if (checkoutLoaded) {
      try {
        Klarna.Payments.init({
          client_token: paymentSession.data.clientToken as string,
        });

        Klarna.Payments.load(
          {
            container: "#klarna-payments-container",
          },
          {},
          function ({ show_form }) {
            setPaymentOptionNotAvailable(!show_form);
          },
        );
      } catch (error) {
        console.error("Klarna Payments Error: ", error);
        setPaymentOptionNotAvailable(true);
      }
    }
  }, [checkoutLoaded, paymentSession]);

  return (
    <>
      <Script id="_next-klarna-payments" src={`https://x.klarnacdn.net/kp/lib/v1/api.js`} onReady={() => setCheckoutLoaded(true)} />
      <div
        id="klarna-payments-container"
        key={id}
        className={clsx({
          hidden: paymentOptionNotAvailable === true,
        })}
      ></div>
      {paymentOptionNotAvailable === true && (
        <div>Leider steht Ihnen die Klarna Zahlungsart nicht zur Verfügung. Bitte nutzen Sie stattdessen eine andere Zahlungsart.</div>
      )}
    </>
  );
};
