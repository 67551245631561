import { useCart, useUpdateCart } from "medusa-react";
import React from "react";
import { FormProvider } from "react-hook-form";

import { RequiredLabel } from "@/components/Form/required-label";
import { Flyout } from "@/components/Modal/Flyout";
import { AddressInterface } from "@/components/pages/account/AddAddress";
import medusaRequest from "@/data/medusa-fetch";
import { medusaClient } from "@/lib/config";
import { useAccount } from "@/lib/contexts/account-context";
import { CHECKOUT_ADDRESS, useSideModal } from "@/lib/contexts/sidemodal-context";
import { handleError } from "@/lib/util/handle-error";
import { AddressForm, validateFormData } from "@/modules/checkout/components/Address/AddressForm";
import { addressPayload } from "@/modules/checkout/components/Address/AddressModal";
import { BaseActionInterface } from "@/modules/checkout/components/Address/Modal/base-action";
import Button from "@/modules/common/components/button";

interface UpdateAddressInterface extends BaseActionInterface {
  onSelect: (address: AddressInterface) => void;
}

export const UpdateAddressAction = ({ onClose, BackLink, modalForm, selectBillingAddress, onSelect }: UpdateAddressInterface) => {
  const {
    modal: { isShow, name },
  } = useSideModal();
  const isOpen = name === CHECKOUT_ADDRESS && isShow;
  const { cart, setCart } = useCart();
  const updateCart = useUpdateCart(cart?.id ?? "");
  const { customer, refetchCustomer: refetch } = useAccount();

  const { handleSubmit, reset } = modalForm;

  const onUpdate = handleSubmit(async (data: AddressInterface) => {
    if (!(await validateFormData(modalForm, data))) {
      return;
    }
    const address = addressPayload(data);

    if (data.id) {
      medusaClient.customers.addresses.updateAddress(data.id, address).then(() => {
        refetch();
        reset();
        onSelect(address);
        onClose();
      });
    }
  });

  const onGuestUpdate = handleSubmit(async (data: AddressInterface) => {
    if (!(await validateFormData(modalForm, data))) {
      return;
    }
    const address = addressPayload(data);

    if (data.id) {
      updateCart.mutate(selectBillingAddress ? { billing_address: address } : { shipping_address: address }, {
        onSuccess: async ({ cart }) => {
          if (cart.metadata?.shippingCostUpdateRequired) {
            const cartValidation = await medusaRequest("POST", `/carts/${cart.id}/validate-shipping`);
            if (cartValidation.ok) {
              setCart(cartValidation.body.cart);
            } else {
              handleError(new Error(`Error validating shipping for cart ${cart.id}`));
            }
          } else {
            setCart(cart);
          }

          close();
        },
      });
    }
  });

  const submitButton = (
    <Button className="py-2 px-4 bg-pink-600 text-white rounded hover:bg-pink-700 mr-2 uppercase w-full" onClick={customer ? onUpdate : onGuestUpdate}>
      Speichern
    </Button>
  );

  return (
    <Flyout open={isOpen} right={true} onClose={onClose} button={submitButton}>
      <div className="text-lg pb-7">
        {customer && BackLink}
        <p className="font-extrabold text-center text-lg mb-3">Adresse auswählen</p>
        <hr className="mt-7 mb-12 h-0.5 border-t-0 bg-black opacity-100" />
        <FormProvider {...modalForm}>
          <AddressForm isBillingAddress={selectBillingAddress}>
            <RequiredLabel />
          </AddressForm>
        </FormProvider>
      </div>
    </Flyout>
  );
};
