"use client";

import clsx from "clsx";
import React, { useState } from "react";

import { ArrowDropDownIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropDownIcon";

interface DropdownButtonInterface {
  title: string;
  children: React.ReactNode[];
  className?: string;
  colorClasses?: string;
  arrowClasses?: string;
  closeOnClick?: boolean;
  icon?: React.ReactNode;
  ulPadding?: string;
  defaultOpen?: boolean;
}

export const DropdownButton = ({
  title,
  children,
  className,
  colorClasses,
  closeOnClick,
  arrowClasses,
  icon,
  ulPadding,
  defaultOpen = false,
}: DropdownButtonInterface) => {
  const [isOpen, setOpen] = useState<boolean>(defaultOpen);

  return (
    <>
      <div
        id="dropdownDelayButton"
        data-dropdown-toggle="dropdownDelay"
        data-dropdown-delay="500"
        data-dropdown-trigger="hover"
        onClick={() => (isOpen ? setOpen(false) : setOpen(true))}
        className={`${className} focus:ring-0 focus:outline-none text-sm pl-4 pr-4 py-1 w-100 ${
          !colorClasses ? "bg-ch21-dropdowngray hover:bg-ch21-dropdowngray-hover" : colorClasses
        } break-words`}
      >
        {title}
        {icon}
        <ArrowDropDownIcon className={!arrowClasses ? "w-5 h-5 float-right" : arrowClasses} />
      </div>
      <div
        id="dropdownDelay"
        className={`z-10 block ${!colorClasses ? "bg-ch21-dropdowngray" : colorClasses} divide-y divide-gray-100 shadow ${isOpen ? "block" : "hidden"}`}
      >
        <ul
          className={clsx("text-sm text-black group", ulPadding, { "py-1": ulPadding === undefined })}
          aria-labelledby="dropdownDelayButton"
          onClick={() => closeOnClick && setOpen(false)}
        >
          {children}
        </ul>
      </div>
    </>
  );
};
