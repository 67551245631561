import { PaymentSession } from "@medusajs/medusa";
import { useCart } from "medusa-react";
import Script from "next/script";
import React, { useEffect, useRef, useState } from "react";

import { ConfirmData, useOnConfirm } from "../../overview";

interface PaymentData extends Record<string, unknown> {
  payload: unknown;
  signature: string;
  config: {
    sandbox: boolean;
    merchantId: string;
    publicKeyId: string;
  };
}

declare global {
  const amazon: {
    Pay: {
      renderButton(id: string, config: Record<string, unknown>): void;
    };
  };
}

export const AmazonPayExpressButton = ({ data, className }: { data: PaymentData; className?: string }) => {
  const { cart } = useCart();
  const [checkoutLoaded, setCheckoutLoaded] = useState(false);
  const [id, setId] = useState(0);
  const idInitialized = useRef(-1);

  useEffect(() => {
    setId((id) => id + 1);
  }, [data.payloadJSON, cart?.total]);

  useEffect(() => {
    if (!checkoutLoaded || id === idInitialized.current) {
      return;
    }
    const buttonConfig = {
      // set checkout environment
      sandbox: data.config.sandbox,
      merchantId: data.config.merchantId,
      ledgerCurrency: "EUR",
      // customize the buyer experience
      checkoutLanguage: "de_DE",
      productType: "PayAndShip",
      placement: "Cart",
      buttonColor: "Gold",
      estimatedOrderAmount: {
        amount: ((cart?.total || 0) / 100).toString(),
        currencyCode: cart?.region.currency_code.toUpperCase(),
      },
      // configure Create Checkout Session request
      createCheckoutSessionConfig: {
        payloadJSON: data.payloadJSON,
        signature: data.signature,
        algorithm: data.algorithm,
        publicKeyId: data.config.publicKeyId,
      },
    };
    amazon.Pay.renderButton("#AmazonPayExpressButton-" + id, buttonConfig);
    // In der dev-Umgebung wird der Effect immer 2 mal aufgerufen, mit useRef verhindern wir, dass amazon.Pay 2 mal ausgeführt wird
    idInitialized.current = id;
  }, [checkoutLoaded, id]);

  return (
    <>
      <Script src="https://static-eu.payments-amazon.com/checkout.js" onReady={() => setCheckoutLoaded(true)} />
      {/* Da es kein AmazonPay-Unmount-Button script gibt, sorgen wir durch geänderten key dafür, dass React das alte DOM-Element entfernt und ein neues frisches anlegt, falls sich payloadJSON ändert */}
      <div className={className}>
        <div id={"AmazonPayExpressButton-" + id} key={"btn-" + id} />
      </div>
    </>
  );
};

export const AmazonPayButton = ({
  paymentSession,
  valid,
  confirm,
}: {
  paymentSession: PaymentSession;
  valid: boolean;
  confirm: ConfirmData;
  failedMessage?: string;
}) => {
  const [checkoutLoaded, setCheckoutLoaded] = useState(false);
  const [id, setId] = useState(0);
  const idInitialized = useRef(-1);
  const onConfirm = useOnConfirm();

  useEffect(() => {
    setId((id) => id + 1);
  }, [paymentSession.data.payloadJSON]);

  // Workaround: Der AmazonPay-Button redirected uns direkt weg, daher müssen wir die Confirm-Data direkt immer up-to-date
  // an den Cart schreiben.
  useEffect(() => {
    onConfirm(confirm);
  }, [confirm.accept_contact]);

  useEffect(() => {
    if (!checkoutLoaded || id === idInitialized.current) {
      return;
    }
    const data = paymentSession.data as PaymentData;
    const buttonConfig = {
      // set checkout environment
      sandbox: data.config.sandbox,
      merchantId: data.config.merchantId,
      ledgerCurrency: "EUR",
      // customize the buyer experience
      checkoutLanguage: "de_DE",
      productType: "PayAndShip",
      placement: "Checkout",
      buttonColor: "Gold",
      // configure Create Checkout Session request
      createCheckoutSessionConfig: {
        payloadJSON: data.payloadJSON,
        signature: data.signature,
        algorithm: data.algorithm,
        publicKeyId: data.config.publicKeyId,
      },
    };
    amazon.Pay.renderButton("#AmazonPayButton-" + id, buttonConfig);
    // In der dev-Umgebung wird der Effect immer 2 mal aufgerufen, mit useRef verhindern wir, dass amazon.Pay 2 mal ausgeführt wird
    idInitialized.current = id;
  }, [checkoutLoaded, id]);

  return (
    <>
      <Script src="https://static-eu.payments-amazon.com/checkout.js" onReady={() => setCheckoutLoaded(true)} />
      {/* Da es kein AmazonPay-Unmount-Button script gibt, sorgen wir durch geänderten key dafür, dass React das alte DOM-Element entfernt und ein neues frisches anlegt, falls sich payloadJSON ändert */}
      <div
        className={
          !valid ? "relative before:content-[''] before:cursor-not-allowed before:block before:absolute before:inset-0 before:z-10 opacity-70" : undefined
        }
      >
        <div id={"AmazonPayButton-" + id} key={"btn-" + id} />
      </div>
    </>
  );
};
