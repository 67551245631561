const translations: { [key: string]: string } = {
  Austria: "Österreich",
  Germany: "Deutschland",
  Luxembourg: "Luxemburg",
};

// I need to transform the display name according to the translations object, if no match is found just return as-is
export const translateCountry = (displayName: string): string => {
  return translations[displayName] || displayName;
};
